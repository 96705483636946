import DeleteDraftConsultModal from 'components/Modals/DeleteDraftConsultModal';
import { Dropdown, DropdownItemProps } from 'display';
import { ConsultComposite } from 'hooks';
import { isDraftConsult } from 'lib/consultHelpers';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RouteUrls from 'routing/RouteUrls';
import Strings from 'lib/strings';

interface ConsumerProps {
	consultComposite: ConsultComposite;
}

type ConsultTableActionsDropdownProps = ConsumerProps & RouteComponentProps;

function ConsultTableActionsDropdown({
	consultComposite,
	history
}: ConsultTableActionsDropdownProps): JSX.Element {
	const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);

	const { consultId, caseId } = consultComposite;

	let actions: DropdownItemProps[];

	if (isDraftConsult(consultComposite)) {
		actions = [
			{
				className: 'dropdownEditDraft',
				text: Strings.Dropdowns.EDIT_DRAFT,
				key: 'dropdown-option-edit-draft',
				onClick: () =>
					history.push(
						RouteUrls.buildUrl(RouteUrls.Key.CONSULT_REQUEST_DRAFT, { consultId })
					)
			},
			{
				className: 'dropdownDeleteDraft',
				text: Strings.Dropdowns.DELETE_DRAFT,
				key: 'dropdown-option-delete-draft',
				onClick: () => setIsDeleteModalOpen(true)
			}
		];
	} else {
		actions = [
			{
				className: 'dropdownViewConsult',
				text: Strings.Dropdowns.VIEW_CONSULT,
				key: 'dropdown-option-view-consult',
				onClick: () =>
					history.push(RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DETAIL, { consultId }))
			},
			{
				className: 'dropdownViewPatient',
				text: Strings.Dropdowns.VIEW_PATIENT,
				key: 'dropdown-option-view-patient',
				onClick: () =>
					history.push(RouteUrls.buildUrl(RouteUrls.Key.CASE_DETAIL, { caseId }))
			},
			{
				className: 'dropdownAddDocuments',
				text: Strings.Dropdowns.ADD_DOCUMENTS,
				key: 'dropdown-option-add-doc',
				onClick: () =>
					history.push(
						RouteUrls.buildUrl(
							RouteUrls.Key.CONSULT_ADD_DATA,
							{ consultId },
							`?nextUrl=${history.location.pathname}`
						)
					)
			}
		];
	}

	return (
		<>
			<Dropdown
				className="consultTableActionsDropdown"
				icon="ellipsis vertical"
				direction="left"
				text=" "
				options={actions}
			/>
			<DeleteDraftConsultModal
				consultId={consultComposite.consultId}
				open={isDeleteModalOpen}
				onConfirm={() => setIsDeleteModalOpen(false)}
				onCancel={() => setIsDeleteModalOpen(false)}
			/>
		</>
	);
}

export default withRouter(ConsultTableActionsDropdown);

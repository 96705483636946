import buildDailyConsults from './buildDailyConsults';
import buildEmail from './buildEmail';
import { buildIsActive } from './buildIsActive';
import { buildIsAdmin } from './buildIsAdmin';
import buildLinkToUserDetail from './buildLinkToUserDetail';
import buildName from './buildName';
import buildPhoneNumber from './buildPhoneNumber';
import buildPreviousConsultCount from './buildPreviousConsultCount';
import buildRoles from './buildRoles';
import buildSelectUser from './buildSelectUser';
import buildSpecialties from './buildSpecialties';
import { buildToggleAsAdmin } from './buildToggleAsAdmin';
import { buildToggleMemberRoleCheckbox } from './buildToggleMemberRoleCheckbox';

const userColumns = {
	buildDailyConsults,
	buildEmail,
	buildIsActive,
	buildIsAdmin,
	buildLinkToUserDetail,
	buildName,
	buildPhoneNumber,
	buildPreviousConsultCount,
	buildRoles,
	buildSelectUser,
	buildSpecialties,
	buildToggleAsAdmin,
	buildToggleMemberRoleCheckbox
};

export default userColumns;

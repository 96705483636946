import { Form, RadioProps } from 'display';
import noop from 'lib/noop';
import Strings from 'lib/strings';
import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';

interface BuildRadioFieldSetProps {
	fieldName: string;
	label: string | JSX.Element;
	options: Array<[number | string, string]>;
	isInline?: boolean;
	validate?: (value: string | number, required: boolean) => null | string[];
}

function buildRadioFieldSet({
	fieldName,
	label,
	options,
	validate,
	isInline
}: BuildRadioFieldSetProps): DataFormFieldComponent {
	function defaultValidate(
		value: string | number | undefined,
		required: boolean
	): string[] | null {
		if (required && !value) {
			return [`${Strings.Errors.REQUIRED_FIELD_RADIO} '${label}'.`];
		}
		return null;
	}

	function validateData(
		data: { [key: string]: string },
		required: boolean
	): ReturnType<DataFormFieldComponent['validate']> {
		const validateFunction = validate || defaultValidate;
		const messages = validateFunction(data[fieldName], required);
		return messages ? { fields: [fieldName], messages } : null;
	}

	function RadioField({
		data,
		editableFields,
		errors = {
			fields: [],
			messages: []
		},
		onChange = noop
	}: DataFormFieldComponentProps): JSX.Element | null {
		const value = data[fieldName];
		const hasError = errors.fields.includes(fieldName);
		const canEdit = editableFields.includes(fieldName);

		function onValueChange(
			event: React.SyntheticEvent<HTMLInputElement>,
			eventData: RadioProps
		): void {
			const newValue = eventData.value;
			if (newValue) {
				onChange(event, { name: fieldName, value: newValue });
			}
		}

		function renderFormField(): JSX.Element {
			if (canEdit) {
				return (
					<Form.Group inline={isInline}>
						{options.map(([option, optionLabel]) => {
							const uniqueRadioKey = `${fieldName}-${option}`;
							return (
								<Form.Radio
									key={uniqueRadioKey}
									id={uniqueRadioKey}
									name={fieldName}
									label={optionLabel}
									value={option}
									onChange={onValueChange}
									checked={value === option}
									error={hasError}
								/>
							);
						})}
					</Form.Group>
				);
			} else {
				const selectedOption = options.find(option => option[0] === value);
				const selectedValue = selectedOption?.[1] ?? value;
				return <span>{selectedValue}</span>;
			}
		}

		return (
			<Form.Field error={hasError} key={fieldName}>
				<label>{label}</label>
				{renderFormField()}
			</Form.Field>
		);
	}

	RadioField.FIELD_NAME = fieldName;
	RadioField.validate = validateData;
	RadioField.displayName = `RadioField-${fieldName}`;

	return RadioField;
}

export default buildRadioFieldSet;

import { transformToThriftFilter } from 'api/helpers';
import ConsultsContextProvider from 'components/ContextProviders/ConsultsContextProvider';
import { ConsultsLoadError } from 'components/Messages';
import { Pagination } from 'components/Pagination';
import { usePaginationContext } from 'contexts/PaginationContext';
import { FilterField, Grid, Loader, StrictGridProps } from 'display';
import { ConsultComposite, useConsultsQuery } from 'hooks';
import { Filter, Parameters } from 'hooks/pagination';
import { transformToConsultsComposite } from 'hooks/queries/consult/helpers';
import { objectSnakeToCamel } from 'lib/objectConverter';
import * as React from 'react';
import { Sort, SortDirection } from 'thriftgen/api_types';
import { GetConsultsRequestCamel } from 'thriftgen/thriftCamelTypes';
import DataTable, { DataTableColumn, DataTableProps } from '../DataTable';
import consultColumns from './columns';
import consultFilters from './filters';
import { FilterMenuProps } from 'components/Filters';

const DEFAULT_SORT_KEY = 'updated_at';
const DEFAULT_SORT_DIRECTION = SortDirection.DESCENDING;
interface ConsultTableProps {
	columns: Array<DataTableColumn<ConsultComposite>>;
	formFilters?: FilterMenuProps['filters'];
	popoverFilters: Array<FilterField>;
	setRowProps?: DataTableProps<ConsultComposite>['setRowProps'];
	initialFilters?: Filter[];
	defaultFilters?: Filter[];
	defaultSorts?: Sort[];
	defaultPageSize?: number;
	storageKey?: string;
	className?: string;
	includeClinicalReview?: boolean;
}

function buildRequest(
	parameters: Parameters,
	defaultFilters?: Filter[],
	includeClinicalReview?: boolean
): GetConsultsRequestCamel {
	const { filters, pageCursor } = parameters;

	const requestFilters = [...filters, ...(defaultFilters || [])].map(transformToThriftFilter);
	return {
		pageParams: {
			...parameters,
			filters: requestFilters,
			pageCursor: pageCursor ?? undefined
		},
		includeClinicalReview
	};
}

function PaginatedConsultTable({
	columns,
	setRowProps,
	defaultFilters,
	defaultSorts,
	className,
	includeClinicalReview = false
}: ConsultTableProps): JSX.Element {
	const { parameters, setSorts, setNeighborPageCursors } = usePaginationContext();
	const request = React.useMemo(
		(): GetConsultsRequestCamel =>
			buildRequest(parameters, defaultFilters, includeClinicalReview),
		[defaultFilters, includeClinicalReview, parameters]
	);

	const {
		data,
		error,
		pageMarkers = {
			next_page_cursor: null,
			prev_page_cursor: null
		},
		...consultMutators
	} = useConsultsQuery(request);

	let consultComposites: ConsultComposite[] = [];
	if (data) {
		const dataCamel = objectSnakeToCamel(data);

		consultComposites = transformToConsultsComposite({
			consults: dataCamel.consults,
			partners: dataCamel.partners,
			users: dataCamel.users
		});
	}

	const { next_page_cursor, prev_page_cursor } = pageMarkers;
	React.useEffect((): void => {
		setNeighborPageCursors({
			nextPageCursor: next_page_cursor,
			prevPageCursor: prev_page_cursor
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [next_page_cursor, prev_page_cursor]);

	React.useEffect((): void => {
		if (parameters.sorts.length === 0) {
			setSorts(
				defaultSorts ?? [
					{
						attribute: DEFAULT_SORT_KEY,
						direction: DEFAULT_SORT_DIRECTION
					}
				]
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (error) {
		return <ConsultsLoadError message={error.message} />;
	}

	if (data) {
		return (
			<ConsultsContextProvider data={data} mutators={consultMutators}>
				<DataTable<ConsultComposite>
					data-testid="consultTable"
					columns={columns}
					data={consultComposites}
					emptyMessage="No consults found at this time."
					setRowProps={setRowProps}
					className={className}
				/>
			</ConsultsContextProvider>
		);
	}

	return <Loader active={true} data-testid="consultTable-loader" />;
}

function ConsultTable(props: ConsultTableProps): JSX.Element {
	const { storageKey, formFilters, popoverFilters, initialFilters, defaultPageSize } = props;

	const columnCount: StrictGridProps['columns'] = React.useMemo(() => {
		if (formFilters) {
			return 2;
		} else {
			return 1;
		}
	}, [formFilters]);

	return (
		<Pagination
			storageKey={storageKey}
			initialFilters={initialFilters}
			defaultPageSize={defaultPageSize}>
			<Grid columns={columnCount} stackable verticalAlign="top">
				{formFilters && (
					<Grid.Column>
						<Pagination.FilterForm filters={formFilters} />
					</Grid.Column>
				)}
				<Grid.Column>
					<Pagination.FilterPopover filters={popoverFilters} />
				</Grid.Column>
			</Grid>
			<PaginatedConsultTable {...props} />
			<Pagination.AllPageControls />
		</Pagination>
	);
}

ConsultTable.Columns = consultColumns;
ConsultTable.Filters = consultFilters;

export default ConsultTable;

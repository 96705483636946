import { useDictationContext } from 'contexts/DictationContext';
import { ContentState, convertToRaw, convertFromHTML, EditorState } from 'draft-js';
import { convertFromHTML as draftConvertFromHTML, convertToHTML } from 'draft-convert';
import { css, cx } from 'emotion';
import { isMobile } from 'lib/userAgent';
import { draftToMarkdown } from 'markdown-draft-js';
import * as React from 'react';
import { useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { TextArea, TextAreaProps } from '.';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import { borderRadius, colors } from 'styles';
import DictationButtonSection from 'display/SemanticUI/Dictation/DictationButtonSection';

const styles = {
	wrapper: css`
		border-radius: ${borderRadius.l2};
		border: 1px solid ${colors.info['500']};
		background-color: ${colors.white};
		&:has(.public-DraftEditor-content[contenteditable='false']) {
			background-color: ${colors.neutral['50']};
		}
	`,
	wrapperError: css`
		border-color: ${colors.problem.base} !important;
	`,
	toolbar: css`
		background-color: ${colors.white};
		border-radius: ${borderRadius.l2} ${borderRadius.l2} 0 0;
		border: 0;
		border-bottom: 1px solid ${colors.info['500']};
	`,
	editor: css`
		padding: 10px;
	`,
	textArea: css`
		width: 100%;
		min-height: 8rem;
		padding: 1rem;
	`
};

const draftToMarkdownOptions = {
	preserveNewlines: true,
	styleItems: {
		UNDERLINE: {
			open: function open() {
				return '<u>';
			},

			close: function close() {
				return '</u>';
			}
		}
	}
};

interface RichTextEditorProps {
	richTextEditorContentValues?: RichTextEditorContentValues;
	placeholder?: string;
	onChange: (content: RichTextEditorContentValues) => void;
	onFocus?: (event: React.SyntheticEvent) => void;
	wrapperClassNameOverride?: string;
	toolbarClassNameOverride?: string;
	editorClassNameOverride?: string;
	mobileClassName?: string;
	hasError?: boolean;
	focusOnLoad?: boolean;
}

interface RichTextEditorContentValues {
	markdown: string;
	editorState?: EditorState;
}

function transformMarkdownContent(content: string) {
	if (isMobile()) {
		return content;
	} else {
		return content.replace(/\n/g, '\n\n');
	}
}

function getMarkdownFromState(editorState: EditorState | undefined): string {
	let markdownString;
	if (editorState) {
		const content = editorState.getCurrentContent();
		const rawObject = convertToRaw(content);
		markdownString = draftToMarkdown(rawObject, draftToMarkdownOptions);
		markdownString = transformMarkdownContent(markdownString);
	}
	return markdownString || '';
}

function convertTextToEditorState(text: string): EditorState {
	const blocksFromHTML = convertFromHTML(text);
	const state = ContentState.createFromBlockArray(
		blocksFromHTML.contentBlocks,
		blocksFromHTML.entityMap
	);
	return EditorState.createWithContent(state);
}

export default function RichTextEditor({
	richTextEditorContentValues,
	placeholder,
	onChange,
	onFocus,
	wrapperClassNameOverride,
	toolbarClassNameOverride,
	editorClassNameOverride,
	mobileClassName,
	hasError,
	focusOnLoad
}: RichTextEditorProps): JSX.Element {
	const toolbar = {
		options: ['inline', 'list', 'link', 'remove', 'history'],
		inline: {
			options: ['bold', 'italic', 'underline', 'strikethrough']
		},
		remove: { title: 'Remove Formatting' }
	};

	const [editorState, setEditorState] = React.useState(
		richTextEditorContentValues?.editorState || EditorState.createEmpty()
	);

	const {
		dictationResults,
		isListening,
		getFinalHTMLText,
		prepareForTranscribing,
		isSocketOpen,
		hasDictationBeenUsed
	} = useDictationContext();

	function onEditorStateChange(editorStateChanged: EditorState) {
		const markdown = getMarkdownFromState(editorStateChanged);
		onChange({
			markdown,
			editorState: editorStateChanged
		});
	}

	useEffect(() => {
		const state = richTextEditorContentValues?.editorState || EditorState.createEmpty();
		setEditorState(state);
	}, [richTextEditorContentValues]);

	useEffect(() => {
		if (dictationResults?.transcript.length) {
			replaceEditorText(dictationResults.transcript);
		}
		// this is ignored because we don't want replaceEditorText to have to be a useCallback hook
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dictationResults]);

	useEffect(() => {
		if (hasDictationBeenUsed) {
			setTimeout(() => setEditorState(editorState), 0);
		}
		// this is ignored because we want to trigger an effect based on changes to isListening
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isListening]);

	useEffect(() => {
		if (isSocketOpen === false && hasDictationBeenUsed) {
			replaceEditorText(getFinalHTMLText());
		}
		// this is ignored because we don't want replaceEditorText to have to be a useCallback hook
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSocketOpen]);

	function onTranscribeStart() {
		SitkaLogger.logMessage('Transcription started', LogEventName.DICTATION);
		replaceEditorText(prepareForTranscribing(convertToHTML(editorState.getCurrentContent())));
	}

	function onTranscribeStop() {
		SitkaLogger.logMessage('Transcription stopped', LogEventName.DICTATION);
	}

	function replaceEditorText(htmlText: string) {
		// finalTranscript will be an html string representing all text in the editor after being processed
		// by TranscriptionProcessorService, so convert it back into a ContentState object before using it to
		// create a new EditorState object to replace the current one
		const content = draftConvertFromHTML(htmlText);
		const newState = EditorState.createWithContent(content);
		const updatedState = EditorState.moveFocusToEnd(newState);
		onEditorStateChange(
			EditorState.push(updatedState, updatedState.getCurrentContent(), 'insert-characters')
		);
	}

	function onTextAreaChangeHandler(
		_event: React.FormEvent<HTMLTextAreaElement>,
		{ value }: Omit<TextAreaProps, 'number'>
	) {
		onChange({
			markdown: value
		});
	}

	const textRef = React.useRef<TextArea>(null);

	function getCustomButtons() {
		const components = [];

		components.push(
			<DictationButtonSection
				onTranscribeStart={onTranscribeStart}
				onTranscribeStop={onTranscribeStop}
			/>
		);

		return components;
	}

	React.useEffect(() => {
		if (isMobile() && textRef?.current && focusOnLoad) {
			textRef.current.focus();
			// iOS hack
			setTimeout(() => window.scrollTo(0, 0), 10);
		}
	}, [textRef, focusOnLoad]);

	return isMobile() ? (
		<TextArea
			placeholder={placeholder}
			onChange={onTextAreaChangeHandler}
			onClick={onFocus}
			className={cx(styles.textArea, mobileClassName, hasError && styles.wrapperError)}
			value={richTextEditorContentValues?.markdown}
			ref={textRef}
		/>
	) : (
		<Editor
			editorState={editorState}
			wrapperClassName={
				wrapperClassNameOverride || cx(styles.wrapper, hasError && styles.wrapperError)
			}
			toolbarClassName={toolbarClassNameOverride || styles.toolbar}
			editorClassName={editorClassNameOverride || styles.editor}
			toolbar={toolbar}
			toolbarCustomButtons={getCustomButtons()}
			placeholder={placeholder}
			onEditorStateChange={onEditorStateChange}
			onFocus={onFocus}
			readOnly={isListening}
			handlePastedText={() => false}
		/>
	);
}

export { RichTextEditorContentValues, getMarkdownFromState, convertTextToEditorState };

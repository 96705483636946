import { Token } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { EXPIRE_COUNTDOWN_MILLISECONDS } from 'lib/okta/OktaConfigHelpers';
import * as React from 'react';
import useCountDown from 'react-countdown-hook';
import { User } from 'thriftgen/api_types';
import { getTokenTimeToLive } from './helpers';

interface UseSessionExpiryState {
	countdownId: undefined | number;
	sessionValidated: boolean;
}

interface UseSessionExpiryOptions {
	actorExercisesOkta: User['exercises_okta'];
	onBeforeExpired: () => void;
	onExpired: () => void;
	onRenewed: (token: Token) => void;
	onStart: (token: Token) => void;
}

interface UseSessionExpiryResult {
	clearCountdown: () => void;
	countdownStarted: boolean;
	countdownTimeLeft: number;
}

const getInitialState = (): UseSessionExpiryState => ({
	countdownId: undefined,
	sessionValidated: false
});

function useSessionExpiry({
	actorExercisesOkta,
	onBeforeExpired,
	onExpired,
	onRenewed,
	onStart
}: UseSessionExpiryOptions): UseSessionExpiryResult {
	const { authState, oktaAuth } = useOktaAuth();
	const [state, setState] = React.useState<UseSessionExpiryState>(getInitialState());

	const [timeLeft, { start: startCountdown, pause: pauseCountdown }] = useCountDown(
		EXPIRE_COUNTDOWN_MILLISECONDS
	);

	const countdownStarted = Boolean(state.countdownId);

	const clearCountdown = React.useCallback(() => {
		window.clearTimeout(state.countdownId);
		pauseCountdown();
		setState(current => ({ ...current, countdownId: undefined }));
	}, [state.countdownId]);

	React.useEffect((): (() => void) => {
		// This hook adjusts the countdown timer once if the window
		// is focused after the countdown has started. This is mostly
		// negligible if the tab is in foreground. In cases where the tab
		// has been put to sleep by the browser, it will make sure that
		// the expiration countdown is accurate when the tab is refocused.

		function adjustCountdown(token: Token): void {
			const remainingTime = getTokenTimeToLive(token);
			window.clearTimeout(state.countdownId);
			startCountdown(remainingTime);
			setState(current => ({
				...current,
				countdownId: window.setTimeout(onExpired, remainingTime)
			}));
			window.removeEventListener('focus', onWindowFocus);
		}

		function onWindowFocus(): void {
			oktaAuth.tokenManager.get('accessToken').then(adjustCountdown).catch(onExpired);
		}

		if (countdownStarted) {
			window.addEventListener('focus', onWindowFocus, { once: true });
		}

		return () => {
			if (countdownStarted) {
				window.removeEventListener('focus', onWindowFocus);
			}
		};
	}, [countdownStarted, onExpired, oktaAuth.tokenManager, startCountdown]);

	React.useEffect(() => {
		// This hook verifies whether the access token is invalid or expired at mounting time.
		// If the token is valid we start monitoring for user activity.
		// If the token is invalid we log the user out.
		// This is meant to clean up expired tokens that may still be in storage during page load.
		// See https://github.com/okta/okta-auth-js#tokenmanagerhasexpiredtoken
		if (authState) {
			if (authState.isPending || state.sessionValidated) {
				return;
			}

			setState(current => ({ ...current, sessionValidated: true }));

			if (authState.isAuthenticated) {
				oktaAuth.tokenManager
					.get('accessToken')
					.then((token: Token): void => {
						const hasInvalidToken = !token || oktaAuth.tokenManager.hasExpired(token);
						if (hasInvalidToken) {
							onExpired();
						} else {
							onStart(token);
						}
					})
					.catch(onExpired);
			} else if (actorExercisesOkta) {
				onExpired();
			}
		}
	}, [
		authState,
		actorExercisesOkta,
		oktaAuth.tokenManager,
		onExpired,
		onStart,
		state.sessionValidated
	]);

	React.useEffect((): void | (() => void) => {
		// This hook sets up the access token expiration listener
		// for users authenticated to Okta.
		if (!authState?.isAuthenticated) {
			return;
		}

		function onExpiredEvent(key: string): void {
			if (key === 'accessToken') {
				startCountdown();
				onBeforeExpired();
				setState(current => ({
					...current,
					countdownId: window.setTimeout(onExpired, EXPIRE_COUNTDOWN_MILLISECONDS)
				}));
			}
		}

		function onRenewEvent(key: string, newToken: Token): void {
			if (key === 'accessToken') {
				onRenewed(newToken);
			}
		}

		oktaAuth.tokenManager.on('renewed', onRenewEvent);
		oktaAuth.tokenManager.on('expired', onExpiredEvent);
		return () => {
			oktaAuth.tokenManager.off('expired', onExpiredEvent);
			oktaAuth.tokenManager.off('renewed', onRenewEvent);
		};
	}, [authState, oktaAuth.tokenManager, onBeforeExpired, onExpired, onRenewed]);

	return {
		countdownStarted,
		countdownTimeLeft: timeLeft,
		clearCountdown
	};
}

export { useSessionExpiry, UseSessionExpiryOptions, UseSessionExpiryResult };

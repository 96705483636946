const Strings = {
	Buttons: {
		ADD: 'Add',
		ADD_CONTACT_INFORMATION: 'Add Contact Information',
		ADD_DOCUMENT: 'Add Document',
		ADD_DOCUMENTS: 'Add Documents',
		ADD_LICENSE: 'Add License',
		ADD_MORE: 'Add More',
		ADD_UNAVAILABILITY_DATES: 'Add Unavailability Dates',
		ATTACH_DOCUMENTS: 'Attach Document',
		BACK: 'Back',
		CANCEL: 'Cancel',
		CHANGE_PASSWORD: 'Change Password',
		CLOSE: 'Close',
		CLOSE_CONSULT: 'Close Consult',
		COLLAPSE: 'Collapse',
		CONFIRM_SETTINGS: 'Confirm Settings',
		CREATE_PARTNER: 'Create Partner',
		CREATE_USER: 'Create User',
		DELETE_USER_LICENSE: 'Delete User License',
		DELETE_USER_TIMEFRAME_RESTRICTION: 'Delete Date',
		DELETE_DRAFT: 'Delete Draft',
		DISABLE: 'Disable',
		DISMISS: 'Dismiss',
		EDIT: 'Edit',
		EDIT_PATIENT_DETAILS: 'Edit Patient Details',
		EDIT_LICENSE: 'Edit License',
		ENABLE: 'Enable',
		EXPAND: 'Expand',
		GOOGLE_CHROME: 'Google Chrome',
		GO_TO_CONSULT_PAGE: 'Go To Consult Page',
		HELP: 'Help',
		JOIN_FAST_TRACK_CONSULT: 'Join Fast Track Consult',
		LOG_OUT: 'Log Out',
		MERGE: 'Merge',
		MINIMIZE: 'Minimize',
		MY_CONSULTS: 'My Consults',
		MY_SITKA: 'My Sitka',
		NEW_CONSULT: 'New Consult',
		NEW_PATIENT_MESSAGE: 'New Patient Message',
		NEXT: 'Next',
		NEXT_DOCUMENT: 'Next Document',
		NEXT_PAGE: 'Next Page',
		NO: 'No',
		OKAY: 'Okay',
		OPEN_SYSTEM_PREFERENCES: 'Open System Preferences',
		PAUSE: 'Pause',
		PREVIOUS_DOCUMENT: 'Previous Document',
		PREVIOUS_PAGE: 'Previous Page',
		POST_MESSAGE: 'Post Message',
		PRINT_REPORT: 'Print Report',
		RECORD: 'Record',
		RECORD_AGAIN: 'Record Again',
		RECORD_A_MESSAGE: 'Record a Message',
		REMOVE_SPECIALIST: 'Remove Specialist',
		REMOVE_USER_FROM_PARTNER: 'Remove User From Partner',
		REPLY: 'Reply',
		RESEND_MESSAGE: 'Resend Message',
		RESHARE_WITH_PATIENT: 'Reshare With Patient',
		RESUME: 'Resume',
		RETURN_HOME: 'Return Home',
		RETURN_TO_CONSULT: 'Return to consult',
		RETURN_TO_PATIENT: 'Return to patient',
		RETURN_TO_MY_PATIENTS: 'Return to My Patients',
		RETURN_TO_QUEUE: 'Return to queue',
		SAVE: 'Save',
		SEND: 'Send',
		SEND_INVITE: 'Send Invite',
		SHARE_WITH_PATIENT: 'Share With Patient',
		SUBMIT_REQUEST: 'Submit Request',
		VIEW: 'View',
		VIEW_MY_MESSAGES: 'View My Messages'
	},
	Dropdowns: {
		ADD_DOCUMENTS: 'Add Documents',
		DELETE_DRAFT: 'Delete Draft',
		EDIT_DRAFT: 'Edit Draft',
		VIEW_CONSULT: 'View Consult',
		VIEW_PATIENT: 'View Patient'
	},
	Errors: {
		ATHENA_FILE_INPUT_ERROR: 'Sorry, we were not able to retrieve a list of your documents.',
		ATHENA_FILE_INPUT_TRY_AGAIN: 'Click here to try again.',
		INFORMATION_ENTERED_DOES_NOT_MATCH_ERROR:
			'The information you entered does not match our records. Please resolve the errors in the manually entered patient data.',
		PLEASE_SELECT_A_SPECIALTY: 'Please select a specialty.',
		VERIFICATION_CODE: 'Verification code cannot be empty.',
		CORRECTIVE_ACTION_TEXT: 'Corrective Actions Needed cannot be empty.',
		CLINICAL_REVIEW_FORM_LOAD: 'Sorry, we were not able to retrieve the clinical review form.',
		REQUIRED_FIELD_RADIO: 'Please choose an option for',
		REJECTED_FILE_EXTENSION:
			'These files cannot be uploaded because they are an unsupported file type:',
		REJECTED_FILE_MIME_TYPE:
			'Our system could not process the below files because their file extension does not match their type. This usually happens when a file was not saved or converted properly. Please ensure that the file was saved correctly and try again. If your issue persists, contact our support team.'
	},
	Headers: {
		ADD_DOCUMENT: 'Add Document',
		CLINICAL_DATA: 'Clinical Data',
		CLINICAL_REVIEW: 'Clinical Review',
		CONSULT_SUMMARY_DASHBOARD: 'Consult Summary Dashboard',
		CREATE_REQUEST: 'Create Request',
		DEVICE_SETTINGS: 'Device Settings',
		DOCUMENTS: 'Documents',
		EDIT_USER_DETAILS: 'Edit User Details',
		FILES_TO_UPLOAD: 'Files To Upload',
		MY_ACCOUNT: 'My Account',
		MY_PATIENTS: 'My Patients',
		PAST_CONSULTS: 'Past Consults',
		VERIFY_YOUR_IDENTITY: 'Verify Your Identity'
	},
	Labels: {
		ADMIN: 'Admin',
		ATHENA_FILE_INPUT_HELP_LABEL: 'Is a file missing?',
		ATHENA_FILE_INPUT_INSTRUCTIONS: 'Select files to include',
		ATHENA_FILE_INPUT_EMPTY: 'There are no documents available to attach.',
		BILLABLE: 'Billable',
		BROWSE_TO_UPLOAD: 'browse to upload',
		CAMERA_SOURCE: 'Camera Source',
		CLINICAL: 'Clinical',
		CLINICAL_QUESTION: 'Clinical Question',
		CONSULT_INFO: 'Consult Info',
		CONSULT_REQUEST_TIPS: 'Consult Request Tips',
		CORRECTIVE_ACTION_NEEDED: 'Is corrective action needed for any of the items above?',
		CORRECTIVE_ACTION_TEXT: 'Consult Comments (must complete if corrective action is needed)',
		CORRECTIVE_ACTION_TEXT_PLACEHOLDER: 'List issues to be discussed with SMA',
		DAILY_CONSULTS: 'Daily Consults Assigned / Limit',
		DOCUMENTS: 'Documents',
		END_DATE: 'End Date',
		EXPIRATION_DATE: 'Expiration Date',
		MICROPHONE_SOURCE: 'Microphone Source',
		NO_OF_CONSULTS_RESPONDED: '# Of Consults Responded',
		OPERATIONS: 'Operations',
		PAST_CONSULTS: 'Past Consults',
		PATIENT_BACKGROUND: 'Patient Background',
		PATIENT_EMAIL: 'Patient Email (optional)',
		PATIENT_FIRST_NAME: 'Patient First Name',
		PATIENT_LAST_NAME: 'Patient Last Name',
		PATIENT_NAME: 'Patient Name',
		PATIENT_ID: 'Patient ID',
		PATIENT_BIRTHDATE: 'Birthdate',
		PATIENT_TREATMENT_LOCATION: 'Patient Treatment Location',
		PHONE_LABEL: 'Patient Phone Number (optional)',
		PROVIDER_MESSAGES: 'Provider Messages',
		RECORD: 'Record',
		REPORTABLE: 'Reportable',
		REQUESTOR: 'Requestor',
		SEARCH_PARTNER_PLACEHOLDER: 'Search by partner name',
		SEARCH_PATIENT_PLACEHOLDER: 'Search Patient',
		SEARCH_PATIENT_NAME_PLACEHOLDER: 'Search by patient name',
		SEARCH_CITY_PLACEHOLDER: 'Search by city',
		SEARCH_USER_PLACEHOLDER: 'Search by name or email address',
		SELECT_A_SPECIALTY: 'Select a Specialty',
		SELECT_FILES: 'Select Files',
		SHOW_MORE: 'Show More',
		SHOW_LESS: 'Show Less',
		SLA_COMPLIANT: 'SLA Compliant',
		SMA: 'SMA',
		SPECIALTY: 'Specialty',
		START_DATE: 'Start Date',
		STATE: 'State',
		SUMMARY: 'Summary',
		SUPPORT: 'Support',
		UNLIMITED: 'Unlimited',
		USER: 'User',
		USER_NAME: 'User Name',
		VERIFICATION_CODE: 'Verification Code',
		VERIFICATION_CODE_PLACEHOLDER: 'xxxxxx',
		VIDEO_REQUEST: 'Video Request',
		WRITTEN_REQUEST: 'Written Request'
	},
	Modals: {
		CLOSE_CONSULT_TITLE: 'Are you sure?',
		CLOSE_CONSULT_TEXT_PART_1: `Closing this consult is a permanent action. You will not be able to send or receive messages after the consult is closed.`,
		CLOSE_CONSULT_TEXT_PART_2:
			'If closed, a copy of your consult summary will be available in the Documents tab of this consult.',
		DELETE_USER_LICENSE_TITLE: 'Are you sure?',
		DELETE_USER_LICENSE_TEXT: 'This will remove the following license:',
		DELETE_USER_TIMEFRAME_RESTRICTION_TITLE: 'Are you sure?',
		DELETE_USER_TIMEFRAME_RESTRICTION_TEXT: 'This will remove the following date:',
		REMOVE_SPECIALIST_TITLE: 'Are you sure?',
		REMOVE_SPECIALIST_TEXT: 'This will remove the assigned specialist from this consult.',
		REMOVE_USER_FROM_PARTNER_TITLE: 'Are you sure?',
		REMOVE_USER_FROM_PARTNER_TEXT: 'This will remove the user from the partner.',
		MERGE_CASES_TITLE: 'Are you sure?',
		MERGE_CASES_DESCRIPTION: 'This will merge the target case with the current one.'
	},
	Navbar: {
		CLINICAL_REVIEW: 'Clinical Review',
		CONSULT_SUMMARY_DASHBOARD: 'Consult Summary Dashboard',
		MY_ACCOUNT: 'My Account',
		MY_CONSULTS: 'My Consults',
		MY_PATIENTS: 'My Patients',
		MY_SITKA: 'My Sitka',
		PARTNERS: 'Partners',
		USERS: 'Users'
	},
	Sentences: {
		ATHENA_FILE_INPUT_HELP_CONTENT: [
			'Sitka supports integrated loading of athenahealth clinical documents, progress notes, and lab results which possess a corresponding pdf.',
			'If you would like to attach a document that is not listed here, please download the document from athenahealth and upload it below.'
		],
		CHANGE_IF_PATIENT_LOCATED_IN_DIFFERENT_STATE:
			'Change if the patient is located in a state different than what is displayed.',
		CHECK_EMAIL_FOR_VERIFICATION_CODE:
			'Check your email for a verification code that was sent separately from your access link.',
		CURRENTLY_NO_DOCUMENTS: 'There are currently no documents.',
		DICTATION_DISCLAIMER:
			'[This document was created using a voice recognition transcribing system. Incorrect words or phrases may have been missed during proof reading. Please interpret accordingly or contact me for clarification if necessary.]',
		ENTER_BIRTHDATE_TO_VIEW_YOUR_MESSAGES:
			'Enter your birthdate and access code to view your messages.',
		FILES_SAVED_WHEN_SELECT_GENERIC_BUTTON: 'Files will be saved once you select',
		NO_FILES_SELECTED: 'No files selected.',
		NO_USERS_FOUND: 'No users found at this time.',
		OFFLINE_WARNING:
			'Your internet connection is offline. Please make sure your internet connection is stable before continuing to use Sitka.',
		RECOMMEND_FOLLOWING_INFORMATION:
			'We recommend including the following information for the most optimal consult:',
		REQUEST_PROMPT: 'Please type your request, record a video, or both.',
		SELECT_PATIENT_TREATMENT_STATE:
			'Select the state where the patient is receiving treatment.',
		SEND_AN_EMAIL_WITH_LINK:
			'Send an email or SMS with the link to join this fast track consult.',
		SMA_RESPONSE_UNSAVED_CHANGES_WARNING:
			'It looks like you have not submitted your response, are you sure you want to leave the page?',
		SMA_RESPONSE_CLOSED_CONSULT: 'This consult has been closed and no responses can be added.'
	},
	SnackbarMessages: {
		SMA_AVAILABILITY_SAVED: 'Availability Saved',
		SMA_TIMEFRAME_RESTRICTION_SAVED: 'Unavailability Date Saved',
		SMA_USER_LICENSE_SAVED: 'User License Saved',
		CONSULT_SUMMARY_RESEND_SUCCESS: 'Consult Summary Sent',
		CONSULT_SUMMARY_RESEND_ERROR: 'Error Sending Consult Summary',
		CONSULT_SUMMARY_SAVE_DESTINATION_SUCCESS: 'Consult Destination Saved',
		CONSULT_SUMMARY_SAVE_DESTINATION_ERROR: 'Error Saving Consult Destination',
		CONSULT_SUMMARY_SAVE_STATUS_SUCCESS: 'Consult Status Saved',
		CONSULT_SUMMARY_SAVE_STATUS_ERROR: 'Error Saving Consult Status'
	},
	PLACEHOLDERS: {
		NO_VALUE: '--'
	}
};

export default Strings;

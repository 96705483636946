import * as React from 'react';

interface DeferredProps {
	onComplete: () => Promise<void>;
	onCancel: () => void;
	deferred: boolean;
}

interface UseDeferredOperationOptions<Args, Result> {
	cancelMessage: string;
	operation: (args: Args) => Promise<Result>;
}

interface DeferredOperation<Result> {
	resolve: (result: Result) => void;
	reject: (reason: Error) => void;
	execute: () => Promise<Result>;
}

type UseDeferredOperationResult<Args, Result> = [(args: Args) => Promise<Result>, DeferredProps];

// This hook wraps a function so its execution is deferred until it is manually completed or canceled
// using the handlers provided. This is useful if you need to withhold an operation
// until further asynchronous action takes place (e.g asking for user confirmation).

function useDeferredOperation<Args = undefined, Result = void>({
	cancelMessage,
	operation
}: UseDeferredOperationOptions<Args, Result>): UseDeferredOperationResult<Args, Result> {
	const [deferredOperation, setDeferredOperation] =
		React.useState<null | DeferredOperation<Result>>(null);
	const deferred = deferredOperation !== null;

	function defer(args: Args): Promise<Result> {
		return new Promise<Result>((resolve, reject): void => {
			setDeferredOperation({ resolve, reject, execute: () => operation(args) });
		});
	}

	function onComplete(): Promise<void> {
		if (!deferredOperation) {
			throw new Error(
				'Attempted to complete deferred operation without having been deferred.'
			);
		}

		return deferredOperation.execute().then((result: Result): void => {
			deferredOperation.resolve(result);
			setDeferredOperation(null);
		});
	}

	function onCancel(): void {
		if (!deferredOperation) {
			throw new Error('Attempted to cancel deferred operation without having been deferred.');
		}

		deferredOperation.reject(new Error(cancelMessage));
		setDeferredOperation(null);
	}

	return [
		defer,
		{
			onCancel,
			onComplete,
			deferred
		}
	];
}

export { UseDeferredOperationOptions, UseDeferredOperationResult, useDeferredOperation };

import UploadDropZone from '@rpldy/upload-drop-zone';
import withPasteUpload from '@rpldy/upload-paste';
import { default as Uploady, Batch, useBatchAddListener } from '@rpldy/uploady';
import { css } from 'emotion';
import * as React from 'react';
import { colors } from 'styles';
import { Button, Icon } from '..';
import FileInput from './FileInput';
import { validateFiles } from './helpers';

const styles = {
	icon: css`
		font-size: 4em !important;
		color: ${colors.primary.base};
		margin-bottom: 0.5em !important;
		margin-top: -0.5em !important;
	`,
	uploadDropZone: css`
		padding: 3em;
		background-color: ${colors.white} !important;
		text-align: center;
		position: relative;
	`,
	fileInput: css`
		position: relative;
		z-index: 10;
	`,
	filePasteInput: css`
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		left: 0;
		top: 0;
		cursor: default;
	`
};

const PasteUploadDropZone = withPasteUpload(UploadDropZone);

const DragDropZone = ({
	onFileChange,
	accept,
	label = 'browse to upload'
}: FileInputPasteDropProps) => {
	const [rejectedExtensionFiles, setRejectedExtensionFiles] = React.useState<File[]>([]);
	const [rejectedMimeTypeFiles, setRejectedMimeTypeFiles] = React.useState<File[]>([]);

	const asyncBatchAddListener = async (batch: Batch) => {
		const batchFiles = batch.items.map(item => item.file) as File[];
		const { accepted, rejectedExtension, rejectedMimeType } = await validateFiles(
			batchFiles,
			accept
		);

		setRejectedExtensionFiles(rejectedExtension);
		setRejectedMimeTypeFiles(rejectedMimeType);
		onFileChange(accepted);
	};

	useBatchAddListener((batch: Batch) => {
		asyncBatchAddListener(batch);
	});

	return (
		<PasteUploadDropZone
			className={styles.uploadDropZone}
			data-testid={'consultMediaPasteUploadDropZone'}>
			<Icon name="cloud upload" className={styles.icon}></Icon>
			<div>Drop or paste files to be uploaded</div>
			<div>or</div>
			<FileInput
				className={styles.fileInput}
				accept={accept}
				button={true}
				buttonStyle={Button.variations.minimal}
				id="consultationMedia"
				label={label}
				multiple={true}
				onFileChange={onFileChange}
				rejectedExtensionParentList={rejectedExtensionFiles}
				rejectedMimeTypeParentList={rejectedMimeTypeFiles}
			/>
			<input className={styles.filePasteInput} data-testid={'consultMediaPasteFileInput'} />
		</PasteUploadDropZone>
	);
};

interface FileInputPasteDropProps {
	onFileChange: (files: File[]) => void;
	accept: React.InputHTMLAttributes<HTMLInputElement>['accept'];
	label?: string;
}

function FileInputPasteDrop({
	onFileChange,
	accept,
	label = 'browse to upload'
}: FileInputPasteDropProps): JSX.Element {
	return (
		<Uploady multiple grouped maxGroupSize={100}>
			<DragDropZone onFileChange={onFileChange} accept={accept} label={label} />
		</Uploady>
	);
}

export default FileInputPasteDrop;

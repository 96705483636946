import React from 'react';
import { Avatar, Box, CardMedia, Link, styled, Typography } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { css } from 'emotion';
import { borderRadius, colors, shadows } from 'styles';
import { getFirstNameLastName } from 'lib/userHelpers';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import { Divider } from 'display';
import { Feature, isFeatureEnabled } from 'lib/feature';

interface UserNameAndAvatarProps {
	user: User;
	displayNameFunc: (user: User) => string;
}

const styles = {
	lists: css`
		margin: 0;
	`,
	wrapper: css`
		display: flex;
		align-items: center;

		& > div:first-child {
			margin-right: 8px;
		}
	`
};

function UserNameAndAvatar({ user, displayNameFunc }: UserNameAndAvatarProps): JSX.Element {
	const [open, setOpen] = React.useState(false);

	const displayName = displayNameFunc(user);
	const specialistBioInfo = user.specialist_data?.specialist_bio_info;

	const tileStyles = {
		bioContent: css`
			overflow: auto;
		`,
		largeAvatar: css`
			height: 100%;
		`,
		rightSide: css`
			display: flex;
			flex-direction: column;
			flex-basis: fit-content;

			& > .divider {
				margin: 0.5em 0;
			}
		`,
		leftSide: css`
			flex-basis: fit-content;
			max-width: 200px;
			margin-right: 2em;
		`,
		wrapper: css`
			display: flex;
		`
	};

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: '#fff',
			padding: '1em',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: '600px',
			maxHeight: '400px',
			fontSize: theme.typography.pxToRem(14),
			boxShadow: shadows.l1,
			borderRadius: borderRadius.l2
		}
	}));

	function handleClose() {
		SitkaLogger.logMessage('SMA Bio popup closed', LogEventName.SMA_BIO);
		setOpen(false);
	}

	function handleOpen() {
		SitkaLogger.logMessage('SMA Bio popup opened', LogEventName.SMA_BIO);
		setOpen(true);
	}

	// this function comes from the Material UI documentation examples for the Avatar component
	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: colors.primary[500]
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}

	function getSpecialtyBioInfoForDisplay() {
		if (specialistBioInfo) {
			const displayMap: SpecialistBioInfo = {
				specialty: 'Specialty',
				med_school: 'Medical School',
				residency: 'Residency',
				fellowship: 'Fellowship',
				board_certification: 'Board Certification',
				fun_facts: 'Fun Facts'
			};

			return Object.keys(displayMap).map(key => {
				return specialistBioInfo[key] ? (
					<div key={key}>
						<strong>{displayMap[key]}: </strong>
						{specialistBioInfo[key]}
					</div>
				) : (
					<></>
				);
			});
		} else {
			return <></>;
		}
	}

	const body = (
		<div className={tileStyles.wrapper} data-testid={'popupBody'}>
			{user.specialist_data?.profile_pic_url && (
				<div className={tileStyles.leftSide}>
					<CardMedia
						component="img"
						src={user.specialist_data.profile_pic_url}
						alt={displayName}
						className={tileStyles.largeAvatar}
						data-testid="profilePhoto"
					/>
				</div>
			)}
			<Box className={tileStyles.rightSide}>
				<Typography variant="h6" component="h2">
					{displayName}
				</Typography>
				<Divider />
				<div className={tileStyles.bioContent} data-testid="bioContent">
					{getSpecialtyBioInfoForDisplay()}
				</div>
			</Box>
		</div>
	);

	return (
		<>
			{user &&
				(isFeatureEnabled(Feature.CWA_1567_SMA_BIO_POPUP) &&
				user.is_sitka_medical_associate &&
				specialistBioInfo &&
				Object.keys(specialistBioInfo).length > 0 ? (
					<div className={styles.wrapper}>
						<Avatar
							alt={displayName}
							src={user.specialist_data?.profile_pic_url}
							{...stringAvatar(getFirstNameLastName(user))}
						/>
						<HtmlTooltip
							title={body}
							placement="top"
							open={open}
							onOpen={handleOpen}
							onClose={handleClose}
							data-testid={'nameTooltip'}>
							<Link component="button">{displayName}</Link>
						</HtmlTooltip>
					</div>
				) : (
					<div className={styles.wrapper}>
						<Avatar {...stringAvatar(getFirstNameLastName(user))} />
						<div>{displayName}</div>
					</div>
				))}
		</>
	);
}

export default UserNameAndAvatar;

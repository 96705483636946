import * as React from 'react';
import { Button } from 'display';
import ConsultReportTable from 'components/DataTable/ConsultReportTable/ConsultReportTable';
import { createPortal } from 'react-dom';
import { css } from 'emotion';
import Strings from 'lib/strings';

function PrintConsultReportButton(): JSX.Element {
	const contentRef = React.useRef(document.createElement('iframe'));
	const [mountNode, setMountNode] = React.useState<HTMLElement | null>(null);

	const children = <ConsultReportTable />;
	const styles = {
		container: css`
			display: flex;
			justify-content: flex-end;
			margin-bottom: 1.5em;
			position: relative;
		`,
		iframe: css`
			z-index: -1000;
			position: absolute;
		`
	};

	function getSemanticStyleElement(): HTMLElement | undefined {
		let semanticStyles;
		document.head.childNodes.forEach(node => {
			if (node?.textContent?.includes('semantic')) {
				semanticStyles = document.createElement('style');
				semanticStyles.textContent = node?.textContent;
			}
		});
		return semanticStyles;
	}

	function handleOnClick() {
		const { current } = contentRef;
		if (current.contentDocument) {
			setMountNode(current.contentDocument.body);
		}
	}

	React.useEffect(() => {
		const { current } = contentRef;
		const semanticStyles = getSemanticStyleElement();
		if (semanticStyles) {
			current?.contentDocument?.head.append(semanticStyles);
		}
		if (current.contentWindow) {
			current.contentWindow.onafterprint = () => {
				setMountNode(null);
			};
		}
	}, []);

	React.useEffect(() => {
		if (mountNode) {
			const { current } = contentRef;
			if (current.contentWindow) {
				current.contentWindow.print();
			}
		}
	}, [mountNode]);

	return (
		<div className={styles.container}>
			<Button.Primary size={Button.Size.TINY} onClick={handleOnClick}>
				{Strings.Buttons.PRINT_REPORT}
			</Button.Primary>
			<iframe id="consultSummaryReport" ref={contentRef} className={styles.iframe}>
				{mountNode && createPortal(children, mountNode)}
			</iframe>
		</div>
	);
}

export default PrintConsultReportButton;

import { useOktaAuth } from '@okta/okta-react';
import { AuthenticationError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { Container, Loader } from 'display';
import { css } from 'emotion';
import noop from 'lib/noop';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import * as React from 'react';
import { connect } from 'react-redux';
import { FAILURE, IN_PROGRESS } from 'store/actionStatus';
import { selectLoggedIn, sendOidcLogin } from 'store/auth';
import { AppState } from 'store/types';
import { LoginResponse } from 'thriftgen/api_types';
import { OidcLoginRequestCamel } from 'thriftgen/thriftCamelTypes';
import OktaSessionTimeout from './OktaSessionTimeout';

const style = {
	page: css`
		padding-top: 1.5em;
	`,
	loader: css`
		margin-top: 1em !important;
	`
};

interface ConsumerProps {
	children: JSX.Element;
}

interface DispatchProps {
	sendOidcLogin: (requestCamel: OidcLoginRequestCamel) => Promise<LoginResponse>;
}

interface StoreProps {
	isLoggedIn: boolean;
}

type OktaSecurityConsumerProps = ConsumerProps & DispatchProps & WithApiCallProps & StoreProps;

// This component is responsible for managing the exchange of okta's authentication
// information for sitka authentication information
function OktaSecurityConsumer({
	children,
	isLoggedIn,
	request,
	sendOidcLogin: sendOidcLoginRequest
}: OktaSecurityConsumerProps): JSX.Element | null {
	const { authState, oktaAuth } = useOktaAuth();
	const { data: requestData } = request;
	const { message, status } = requestData;
	// keep track of whether we've already called oidcLogin for this session:
	// if the user is logged in, we can safely assume that oidcLogin has been called
	const [oidcLoginCalled, setOidcLoginCalled] = React.useState<boolean>(isLoggedIn);

	// evaluate the access token's expiration at the time that the page is loaded, and store
	// it's status to the component's local state
	const [hasExpirationBeenEvaluated, setHasExpirationBeenEvaluated] =
		React.useState<boolean>(false);
	const [isAccessTokenExpiredWhenPageLoads, setIsAccessTokenExpiredWhenPageLoads] =
		React.useState<boolean>();

	React.useEffect(() => {
		if (authState?.accessToken && !hasExpirationBeenEvaluated) {
			setHasExpirationBeenEvaluated(true);
			setIsAccessTokenExpiredWhenPageLoads(
				oktaAuth.tokenManager.hasExpired(authState.accessToken)
			);
		}
	}, [authState, hasExpirationBeenEvaluated, oktaAuth]);

	React.useEffect((): void => {
		if (!oidcLoginCalled && authState?.idToken) {
			setOidcLoginCalled(true);
			sendOidcLoginRequest({
				idToken: authState.idToken.idToken,
				accessToken:
					authState.accessToken !== undefined
						? authState.accessToken.accessToken
						: undefined
			}).catch(noop);
		}
	}, [authState, oidcLoginCalled, sendOidcLoginRequest]);

	if (status === IN_PROGRESS) {
		return <Loader active={true} />;
	}

	if (status === FAILURE) {
		return (
			<Container className={style.page}>
				<AuthenticationError message={message} showCallToAction={true} />
			</Container>
		);
	}

	if (isAccessTokenExpiredWhenPageLoads) {
		SitkaLogger.logMessage(
			'OktaSecurityConsumer- Access Token Has Expired, redirect to login page',
			LogEventName.USER_SESSION
		);
		oktaAuth.signInWithRedirect({ originalUri: window.location.href });
		return <Loader active={true} inline="centered" className={style.loader} />;
	}

	function shouldDisplayOktaSessionTimeout(): boolean {
		return (
			!!authState?.accessToken &&
			!isAccessTokenExpiredWhenPageLoads &&
			hasExpirationBeenEvaluated
		);
	}

	return (
		<React.Fragment>
			{shouldDisplayOktaSessionTimeout() && <OktaSessionTimeout />}
			{children}
		</React.Fragment>
	);
}

const mapStateToProps = (store: AppState): StoreProps => {
	const isLoggedIn = selectLoggedIn(store);

	return {
		isLoggedIn
	};
};

export default withApiCall({ sendOidcLogin }, connect(mapStateToProps)(OktaSecurityConsumer));

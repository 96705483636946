import { Form, Paragraph } from 'display';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import * as React from 'react';
import { getFieldsAndSubfieldNames } from './Fields/helpers';
import { validate, determineDisplayFields } from './helpers';
import noop from 'lib/noop';

const styles = {
	form: css`
		margin-bottom: 1em;
	`
};

function isFormEmpty(fields, data) {
	return getFieldsAndSubfieldNames(fields).every(fieldName => !data[fieldName]);
}

function DataForm({ data, editableFields, errors, fields, conditionalFields, onChange, onBlur }) {
	const [displayFields, setDisplayFields] = React.useState([]);

	React.useEffect(() => {
		const displayFields = determineDisplayFields(conditionalFields, data, fields);
		setDisplayFields(displayFields);
	}, [conditionalFields, data, fields]);

	if (onChange === noop && editableFields.length > 0) {
		throw new Error('Cannot create a form with editable fields and no onChange handler');
	}

	if (editableFields.length === 0 && isFormEmpty(displayFields, data)) {
		return <Paragraph>It looks like there&apos;s no information on file.</Paragraph>;
	}

	return (
		<Form className={styles.form} data-testid="dataForm">
			{displayFields.map(Field => {
				return (
					<Field
						data={data}
						editableFields={editableFields}
						errors={errors}
						key={Field.FIELD_NAME}
						onChange={onChange}
						onBlur={onBlur}
					/>
				);
			})}
		</Form>
	);
}

DataForm.validate = validate;

DataForm.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	fields: [],
	conditionalFields: [],
	onChange: noop,
	OnBlur: noop
};

DataForm.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.object,
	fields: PropTypes.arrayOf(PropTypes.elementType),
	conditionalFields: PropTypes.arrayOf(
		PropTypes.shape({
			fieldName: PropTypes.string,
			conditionalFieldName: PropTypes.string,
			action: PropTypes.string,
			values: PropTypes.arrayOf(
				PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
			)
		})
	),
	onChange: PropTypes.func,
	onBlur: PropTypes.func
};

export default DataForm;

import * as React from 'react';

import { PendoGuide, showGuideIfAvailable } from 'lib/pendo';

interface UsePendoGuideOptions {
	guide: PendoGuide;
}

function usePendoGuide({
	guide
}: UsePendoGuideOptions): React.Dispatch<React.SetStateAction<boolean>> {
	const [guideShown, setGuideShown] = React.useState<boolean>(false);

	React.useEffect((): void => {
		if (guideShown) {
			setGuideShown(true);
			showGuideIfAvailable(guide);
		}
	}, [guide, guideShown]);

	return setGuideShown;
}

export { usePendoGuide, UsePendoGuideOptions };

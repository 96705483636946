import * as React from 'react';

interface UseLazyAsyncCallState<Result> {
	data: undefined | Result;
	error: undefined | Error;
	loading: boolean;
}

type Operation<Request, Response> = (args?: Request) => {
	promise: () => Promise<Response>;
};

interface UseLazyAsyncCallActions<Request, Response> {
	dispatch: Operation<Request, Response>;
	reset: () => void;
}

type UseLazyAsyncCallResult<Request, Response> = UseLazyAsyncCallActions<Request, Response> &
	UseLazyAsyncCallState<Response>;

function getInitialState<Response>(): UseLazyAsyncCallState<Response> {
	return {
		data: undefined,
		error: undefined,
		loading: false
	};
}
function useLazyAsyncCall<Request, Response = void>(
	request: (args: Request) => Promise<Response>
): UseLazyAsyncCallResult<Request, Response> {
	const [state, setState] = React.useState<UseLazyAsyncCallState<Response>>(getInitialState());

	function reset(): void {
		setState(getInitialState());
	}

	const boundRequest = (args: Request) => {
		setState(current => ({ ...current, error: undefined, loading: true }));
		const promise = request(args);

		promise
			.then(data => {
				setState(current => ({ ...current, data, error: undefined, loading: false }));
			})
			.catch(err => {
				setState(current => ({ ...current, error: err, loading: false }));
			});

		return {
			promise: () => promise
		};
	};

	return {
		...state,
		dispatch: boundRequest,
		reset
	};
}

export { useLazyAsyncCall, UseLazyAsyncCallResult };

import RecorderStateMachine from 'components/Recorder/RecorderStateMachine';
import withEventReporter from 'components/withEvent/withEventReporter';
import { Loader } from 'display';
import * as React from 'react';
import { EventType } from 'thriftgen/api_types';

interface ReportEventArgs {
	eventType: EventType;
}

interface ConsultationRecorderProps {
	onRecordingComplete: (video: Blob) => void;
	onRecordingReject: () => void;
	onRecordingStart: () => void;
	canRecordScreen?: boolean;
	recorderLauncheraction?: React.ReactNode;
	recordingPreviewAction?: React.ReactNode;
	// Passed by withEventReporter
	reportEvent: (event: ReportEventArgs) => void;
	video: Blob | null;
	recordingId: string;
	isLoading?: boolean;
	hideVideoPreview?: boolean;
}

function ConsultationRecorder({
	reportEvent,
	isLoading,
	...rest
}: ConsultationRecorderProps): JSX.Element {
	return isLoading ? (
		<Loader active={true} />
	) : (
		<RecorderStateMachine eventListener={reportEvent} {...rest} />
	);
}

ConsultationRecorder.defaultProps = {
	video: null
};

export default withEventReporter(ConsultationRecorder);

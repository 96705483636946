import LoginButton from 'components/Login/LoginButton';
import { NavbarItem } from 'components/Navbar/helpers';
import { Button, FullScreenModal, Heading, Menu } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { fontSize, SEMANTIC_COLOR_STRINGS } from 'styles';

const styles = {
	menuContainer: css`
		display: none !important;
		padding-right: 0 !important;

		@media (min-width: 0px) and (max-width: 800px) {
			display: block !important;
		}
	`,
	menuButton: css`
		font-size: ${fontSize[3]} !important;
		padding-left: 1em !important;
		padding-right: 1em !important;
	`,
	menuItems: css`
		padding-bottom: 3rem;

		& .icon.cancel {
			font-size: ${fontSize[5]};
			position: absolute;
			top: 20px;
			right: 10px;
		}

		& .item {
			font-size: ${fontSize[4]} !important;
			margin-bottom: 0.5rem !important;
		}
	`
};

interface ConsumerProps {
	activeItem?: NavbarItem['name'];
	items: NavbarItem[];
	displayLogout?: boolean;
}

type MobileMenuProps = ConsumerProps & RouteComponentProps;

function MobileMenu({
	activeItem,
	items,
	history,
	displayLogout = true
}: MobileMenuProps): JSX.Element {
	const [open, setOpen] = React.useState<boolean>(false);

	const root = document.getElementById('app-root');

	function closeMenu() {
		toggleMenu(false);
	}

	function handleItemClick(url: string) {
		closeMenu();
		redirectToUrl(url);
	}

	function openMenu() {
		toggleMenu(true);
	}

	function redirectToUrl(url: string) {
		/* Wait for CSS transition to propagate before changing the url to avoid
		inmediate page jumping. */
		setTimeout(() => history.push(url), 300);
	}

	function toggleMenu(menuState: boolean) {
		/* Disable underlying body content scrolling while menu is open */
		if (root) {
			root.style.overflow = menuState ? 'hidden' : 'unset';
		}

		setOpen(menuState);
	}

	function renderMenu() {
		return (
			<FullScreenModal open={open} trigger={renderTrigger()} data-testid="mobileMenuModal">
				<section className={styles.menuItems}>
					<Button.Icon
						color={SEMANTIC_COLOR_STRINGS.PRIMARY}
						icon="cancel"
						onClick={closeMenu}
						data-testid="mobileMenuClose"
					/>
					<Menu.Item>
						<Heading as={Heading.Type.H1} size={Heading.Size.T1}>
							Menu
						</Heading>
					</Menu.Item>
					{items.map(({ name, url, text }) => (
						<Menu.Item
							active={activeItem === name}
							key={name}
							onClick={() => handleItemClick(url)}>
							{text}
						</Menu.Item>
					))}
					{displayLogout && (
						<div onClick={closeMenu}>
							<LoginButton responsive={true} />
						</div>
					)}
				</section>
			</FullScreenModal>
		);
	}

	function renderTrigger() {
		return (
			<Button.Outline
				className={styles.menuButton}
				onClick={openMenu}
				data-testid="mobileMenuTrigger">
				Menu
			</Button.Outline>
		);
	}

	return (
		<Menu.Item position="right" className={styles.menuContainer}>
			{renderMenu()}
		</Menu.Item>
	);
}

export default withRouter(MobileMenu);

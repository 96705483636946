import { SubmitButton } from 'components/Buttons';
import { DailyAvailability, DailyConsultsLimit } from 'components/Forms/Fields';
import { Feature, isFeatureEnabled } from 'lib/feature';
import * as React from 'react';
import { DailyAvailabilityCamel, SpecialistDataCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from './DataFormTypes';
import DataFormWrapper from './DataFormWrapper';
import { getFieldsAndSubfieldNames } from './Fields/helpers';

const FIELDS: DataFormFieldComponent[] = [DailyConsultsLimit];
const REQUIRED_FIELDS: string[] = [];

type SpecialistAvailabilityFormData = Pick<SpecialistDataCamel, 'dailyConsultsLimit'> &
	DailyAvailabilityCamel;

interface SpecialistDataFormProps {
	initialData: Partial<SpecialistAvailabilityFormData>;
	onSubmit: (formData: SpecialistAvailabilityFormData) => Promise<void>;
}

function SpecialistDataForm({ initialData, onSubmit }: SpecialistDataFormProps): JSX.Element {
	async function handleSubmit(data: SpecialistAvailabilityFormData): Promise<void> {
		await onSubmit(data);
	}

	const fields: DataFormFieldComponent[] = React.useMemo(() => {
		if (isFeatureEnabled(Feature.CWA_1355_SMA_SCHEDULE)) {
			return [...FIELDS, DailyAvailability];
		} else {
			return FIELDS;
		}
	}, []);

	const editableFields: string[] = React.useMemo(() => {
		return getFieldsAndSubfieldNames(fields);
	}, [fields]);

	return (
		<DataFormWrapper
			data={initialData}
			editableFields={editableFields}
			fields={fields}
			requiredFields={REQUIRED_FIELDS}
			SubmitControl={SubmitButton}
			onSubmit={handleSubmit}
			submitAllFields={true}
		/>
	);
}

export default SpecialistDataForm;
export { SpecialistAvailabilityFormData };

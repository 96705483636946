import * as React from 'react';
import { Popup, Button, PopupProps } from '.';
import { css } from 'emotion';

const styles = {
	triggerButton: css`
		padding: 0 0.6em !important;
		margin: 0;
	`
};

function Tooltip({ className, children, trigger }: PopupProps): JSX.Element {
	const defaultTrigger = React.useMemo(
		() => <Button.Icon icon="question circle" className={className ?? styles.triggerButton} />,
		[className]
	);

	return <Popup trigger={trigger ?? defaultTrigger}>{children}</Popup>;
}

export default Tooltip;

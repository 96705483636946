import { getSpecialtyImage } from 'components/Forms/Fields/PrettySpecialties/specialtyImages';
import { translateSpecialty } from 'components/Forms/Fields/Specialty/helpers';
import { CloseConsultModal } from 'components/Modals/CloseConsultModal';
import { useConsultsContext } from 'contexts/ConsultsContext';
import { Accordion, Dropdown, DropdownItemProps, Header, Text } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { transformToConsultsComposite } from 'hooks/queries/consult/helpers';
import { canCloseOwnConsult, getConsultLink, isDraftConsult } from 'lib/consultHelpers';
import { getFullDateTime } from 'lib/date';
import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import Strings from 'lib/strings';
import { getFirstNameLastName } from 'lib/userHelpers';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { Consult, CONSULT_CLOSED_STATES, User } from 'thriftgen/api_types';
import AssetListItem from './AssetListItem';
import { AssetListItemMetaProps, AssetListItemTitleAreaProps } from './types';

const styles = {
	icon: css`
		height: 2.5rem;
		width: 2.5rem;
	`
};

interface StoreProps {
	actor: User;
}

interface ConsultAssetListItemProps {
	consult: ConsultComposite;
	displayConsultListActions?: boolean;
}

function getAssetTitle(consultComposite: ConsultComposite): AssetListItemTitleAreaProps['title'] {
	let title = translateSpecialty(consultComposite.specialty);
	if (CONSULT_CLOSED_STATES.includes(consultComposite.state)) {
		title += ' : Closed';
	} else if (isDraftConsult(consultComposite)) {
		title += ' : Draft';
	}

	return {
		content: <Text>{title}</Text>,
		url: getConsultLink(consultComposite)
	};
}

function getAssetSubtitle({
	createdAt
}: Pick<ConsultComposite, 'createdAt'>): AssetListItemTitleAreaProps['subtitle'] {
	return {
		text: getFullDateTime(createdAt)
	};
}

function getAssetMeta({
	specialist,
	requestor
}: Pick<ConsultComposite, 'requestor' | 'specialist'>): AssetListItemMetaProps[] {
	return [
		{
			label: 'Requester',
			content: requestor ? getFirstNameLastName(requestor) : '(none)'
		},
		{
			label: 'Responder',
			content: specialist ? getFirstNameLastName(specialist) : '(none)'
		}
	];
}

const ConsultImage = ({
	consultId,
	specialty
}: Pick<ConsultComposite, 'specialty' | 'consultId'>): JSX.Element | null => {
	if (!specialty) {
		return null;
	}

	return (
		<div className={styles.icon} data-testid={`${consultId}-${specialty}`}>
			{getSpecialtyImage(specialty).unselected}
		</div>
	);
};

function ConsultAssetListItemActions({
	consult,
	actor
}: ConsultAssetListItemProps & StoreProps): JSX.Element {
	const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState<boolean>(false);

	const isClosedDisabled = !canCloseOwnConsult(objectCamelToSnake(consult) as Consult, actor);
	const actions: DropdownItemProps[] = [
		{
			text: 'Close Consult',
			disabled: isClosedDisabled,
			onClick: () => !isClosedDisabled && setIsConfirmModalOpen(true),
			className: 'closeConsultDropdownItem'
		}
	];

	function closeConsultConfirmModal(): void {
		setIsConfirmModalOpen(false);
	}

	const { manageConsult } = useConsultsContext();

	return (
		<>
			<Dropdown icon="ellipsis vertical" direction="left" text=" " options={actions} />{' '}
			<CloseConsultModal
				consultId={consult.consultId}
				open={isConfirmModalOpen}
				onConfirm={closeConsultConfirmModal}
				onCancel={closeConsultConfirmModal}
				manageConsult={manageConsult}
			/>
		</>
	);
}

function ConsultAssetListItem({
	consult,
	actor,
	displayConsultListActions
}: ConsultAssetListItemProps & StoreProps): JSX.Element {
	const action = displayConsultListActions ? (
		<ConsultAssetListItemActions consult={consult} actor={actor}></ConsultAssetListItemActions>
	) : (
		<></>
	);

	return (
		<AssetListItem
			thumbnail={<ConsultImage specialty={consult.specialty} consultId={consult.consultId} />}
			title={getAssetTitle(consult)}
			subtitle={getAssetSubtitle(consult)}
			meta={getAssetMeta(consult)}
			action={action}
		/>
	);
}

interface ConsultAssetListProps {
	displaySectionLabel?: boolean;
	displayConsultListActions?: boolean;
	maxPrimaryConsults?: number;
}

function ConsultAssetList({
	displaySectionLabel,
	displayConsultListActions,
	maxPrimaryConsults,
	actor
}: ConsultAssetListProps & StoreProps): JSX.Element {
	const [primaryConsults, setPrimaryConsults] = React.useState<ConsultComposite[]>([]);
	const [secondaryConsults, setSecondaryConsults] = React.useState<ConsultComposite[]>([]);
	const [displaySecondaryConsults, setDisplaySecondaryConsults] = React.useState<boolean>(false);

	const data = useConsultsContext();

	const consultComposites = React.useMemo(() => {
		const dataCamel = objectSnakeToCamel(data);
		return transformToConsultsComposite({
			consults: dataCamel.consults,
			partners: dataCamel.partners,
			users: dataCamel.users
		});
	}, [data]);

	React.useEffect(() => {
		if (!maxPrimaryConsults) {
			setPrimaryConsults(consultComposites);
		} else {
			setPrimaryConsults(consultComposites.slice(0, maxPrimaryConsults));
			setSecondaryConsults(consultComposites.slice(maxPrimaryConsults));
		}
	}, [consultComposites, maxPrimaryConsults]);

	function toggleSecondaryConsults() {
		setDisplaySecondaryConsults(!displaySecondaryConsults);
	}

	function renderConsultAssetListItem(consult: ConsultComposite) {
		return (
			<ConsultAssetListItem
				key={consult.consultId}
				consult={consult}
				actor={actor}
				displayConsultListActions={displayConsultListActions}></ConsultAssetListItem>
		);
	}

	return (
		<div data-testid="ConsultAssetList">
			{primaryConsults.length ? (
				<div data-testid="ConsultAssetListPrimary">
					{displaySectionLabel ? (
						<Header>{Strings.Labels.PAST_CONSULTS}</Header>
					) : (
						<React.Fragment />
					)}
					{primaryConsults.map(renderConsultAssetListItem)}
				</div>
			) : (
				<React.Fragment />
			)}
			{secondaryConsults.length ? (
				<Accordion data-testid="ConsultAssetListSecondary">
					<Accordion.Title
						active={displaySecondaryConsults}
						content={
							displaySecondaryConsults
								? Strings.Labels.SHOW_LESS
								: Strings.Labels.SHOW_MORE
						}
						index={0}
						onClick={toggleSecondaryConsults}
					/>
					<Accordion.Content active={displaySecondaryConsults}>
						{secondaryConsults.map(renderConsultAssetListItem)}
					</Accordion.Content>
				</Accordion>
			) : (
				<React.Fragment />
			)}
		</div>
	);
}

const mapStoreToProps = (store: AppState): StoreProps => {
	const actor = selectAuthUser(store);

	return {
		actor
	};
};

export { ConsultAssetListItem, ConsultAssetListProps };
export default connect(mapStoreToProps)(ConsultAssetList);

import * as React from 'react';

import { DocumentDataAsset, isTiffImage } from 'lib/documentAssets';
import { isVideo } from 'lib/consultDataHelpers';
import scheduling from 'lib/browserScheduling';

import {
	DocumentSource,
	LoadableDocument,
	LoadedDocument,
	LoadingStatus,
	PendingDocument,
	SkippedDocument,
	UseDocumentLoadingOptions,
	UseDocumentLoadingResult
} from './types';

function setDocumentPerStatus(
	document: DocumentDataAsset,
	options: UseDocumentLoadingOptions
): LoadableDocument {
	const shouldSkipTiffImage = options.skipTiffImagePreload && isTiffImage(document);
	const shouldSkipVideo = options.skipVideoPreload && isVideo(document);

	// Skips documents that are not ready or don't have a source url.
	if (
		shouldSkipTiffImage ||
		shouldSkipVideo ||
		!document.isReady ||
		typeof document.presigned_url !== 'string'
	) {
		return {
			id: document.id,
			status: LoadingStatus.SKIPPED,
			content: null,
			source: document.presigned_url
		};
	}

	return {
		id: document.id,
		status: LoadingStatus.PENDING,
		content: null,
		source: document.presigned_url
	};
}

function getInitialState(
	documents: DocumentDataAsset[],
	options: UseDocumentLoadingOptions
): LoadableDocument[] {
	return documents.map(asset => setDocumentPerStatus(asset, options));
}

function requestAndTransformToFile(
	url: DocumentDataAsset['presigned_url'],
	id: DocumentDataAsset['id']
): Promise<File> {
	return fetch(url)
		.then(response => response.blob())
		.then(blob => new File([blob], id));
}

function documentIsPending(document: LoadableDocument): document is PendingDocument {
	return document.status === LoadingStatus.PENDING;
}

function useDocumentLoading(
	documents: DocumentDataAsset[],
	options: UseDocumentLoadingOptions = {
		skipTiffImagePreload: false,
		skipVideoPreload: false
	}
): UseDocumentLoadingResult {
	const [loadableDocuments, setLoadableDocuments] = React.useState<LoadableDocument[]>(
		getInitialState(documents, options)
	);

	function updateDocument<DocumentType extends LoadableDocument>(
		id: LoadableDocument['id'],
		update: Partial<DocumentType>
	): void {
		const updatedLoadableDocuments = loadableDocuments.map(document => {
			if (document.id === id) {
				return {
					...document,
					...update
				};
			}

			return document;
		});

		setLoadableDocuments(updatedLoadableDocuments);
	}

	function getDocumentById(documentId: DocumentDataAsset['id']): LoadableDocument {
		const document = loadableDocuments.find(({ id }) => id === documentId);

		if (!document) {
			throw new Error(`LoadableDocument not found for document ${documentId}`);
		}

		return document;
	}

	function getDocumentSource(documentId: DocumentDataAsset['id']): DocumentSource {
		const document = getDocumentById(documentId);
		return document.content || document.source;
	}

	React.useEffect(() => {
		const documentToLoad = loadableDocuments.find(documentIsPending);

		if (!documentToLoad) {
			return;
		}

		scheduling.requestIdleCallback(() => {
			requestAndTransformToFile(documentToLoad.source, documentToLoad.id)
				.then(file => {
					updateDocument<LoadedDocument>(documentToLoad.id, {
						content: file,
						status: LoadingStatus.LOADED
					});
				})
				.catch(() => {
					updateDocument<SkippedDocument>(documentToLoad.id, {
						status: LoadingStatus.SKIPPED
					});
				});
		});
	}, [loadableDocuments]);

	return {
		getDocumentSource
	};
}

export { useDocumentLoading };

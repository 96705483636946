import * as React from 'react';
import debounce from 'lib/debounce';

import { SearchFilterField } from '../types';
import { Search, SearchProps } from 'display';

const DEBOUNCE_DELAY = 300;

function SearchFilter({
	onChange,
	placeholder = 'Search',
	value = ''
}: Omit<SearchFilterField, 'type'>): JSX.Element {
	const [realtimeValue, setRealtimeValue] = React.useState<string>(value);

	const debouncedOnChange = React.useCallback(debounce(onChange, DEBOUNCE_DELAY), []);

	function onSearchChange(_: React.MouseEvent<HTMLElement>, data: SearchProps): void {
		if (typeof data.value === 'string') {
			setRealtimeValue(data.value);
			debouncedOnChange(data.value);
		}
	}

	return (
		<Search
			onSearchChange={onSearchChange}
			placeholder={placeholder}
			showNoResults={false}
			value={realtimeValue}
		/>
	);
}

export default SearchFilter;

import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import DictationTipsModal from 'components/Modals/DictationTipsModal';
import { Button } from '..';
import { richTextEditorButtonStyles } from './RichTextEditorButtonCSS';

export default function DictationTipsButton() {
	const [isOpen, setIsOpen] = React.useState(false);

	const openModal = () => {
		setIsOpen(true);
	};

	const closeModal = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Popup
				content={'View dictation commands and tips'}
				trigger={
					<div
						className={richTextEditorButtonStyles.buttonWrapper}
						data-testid="dictationTipsButton">
						<Button
							className={richTextEditorButtonStyles.button}
							icon
							data-testid="dictationTipsButton">
							<Icon
								className={richTextEditorButtonStyles.buttonIcon}
								name="help circle"
								onClick={openModal}
							/>
						</Button>
					</div>
				}
				size="small"
				mouseEnterDelay={500}
			/>
			<DictationTipsModal isOpen={isOpen} closeModal={closeModal} />
		</>
	);
}

import { CASE_FILTERS } from 'api/customFilters';
import { transformToThriftFilter } from 'api/helpers';
import { Form, Search, SearchProps, SearchResultData, SearchResultProps } from 'display';
import { useCasesQuery } from 'hooks';
import { Filter } from 'hooks/pagination';
import debounce from 'lib/debounce';
import * as React from 'react';
import { GetCasesRequestCamel, UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormErrors, DataFormFieldComponentProps } from '../DataFormTypes';

const FIELD_NAME = 'patientSearchCaseId';
const FIELD_LABEL = 'Search by patient name or ID / MRN';

function validate(
	data: DataFormFieldComponentProps['data'],
	required: boolean
): DataFormErrors | null {
	const errors: DataFormErrors = {
		fields: [],
		messages: []
	};
	if (required && data[FIELD_NAME] === undefined) {
		errors.fields.push(FIELD_NAME);
		errors.messages.push('Please select a patient.');
		return errors;
	}

	return null;
}

function buildCaseRequest(
	value: string,
	data: DataFormFieldComponentProps['data']
): GetCasesRequestCamel {
	const filters: Filter[] = [
		{
			attribute: CASE_FILTERS.USER_PROFILE,
			value
		}
	];

	if (data.partnerId) {
		filters.push({ attribute: 'partner_id', value: data.partnerId });
	}

	return {
		pageParams: {
			pageSize: 10,
			filters: filters.map(transformToThriftFilter)
		}
	};
}

function formatPatientName({
	firstName,
	lastName,
	patientExternalId
}: Pick<UserInfoCamel, 'firstName' | 'lastName' | 'patientExternalId'>): string {
	return `${firstName} ${lastName} (${patientExternalId})`;
}

function transformCasesToResults(caseComposite: SearchResultProps) {
	const title = formatPatientName(caseComposite.patient);
	return { title, id: caseComposite.caseId };
}

function getValue(data: DataFormFieldComponentProps['data']): string | undefined {
	if (data[FIELD_NAME]) {
		return formatPatientName(data as UserInfoCamel);
	}
	return '';
}

function PatientSearch({
	data,
	errors,
	onChange
}: DataFormFieldComponentProps): JSX.Element | null {
	const [inputValue, setInputValue] = React.useState(getValue(data));
	const [apiSearchValue, setApiSearchValue] = React.useState('');

	const request = React.useMemo(
		(): GetCasesRequestCamel => buildCaseRequest(apiSearchValue, data),
		[apiSearchValue, data]
	);

	const { cases, isValidating } = useCasesQuery(request);

	const debouncedSetSearchValue = React.useRef(
		debounce((value: string) => {
			setApiSearchValue(value || '');
		}, 1000)
	);

	function handleSearchChange(_e: React.MouseEvent, { value }: SearchProps) {
		setInputValue(value);
		debouncedSetSearchValue.current(value || '');
	}

	function handleResultSelect(event: React.MouseEvent, { result }: SearchResultData) {
		const selectedCase = cases?.find(theCase => theCase.caseId === result.id);
		/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		setInputValue(transformCasesToResults(selectedCase).title);
		if (selectedCase) {
			onChange(event, {
				name: FIELD_NAME,
				value: result.id
			});
			const patientDemographicsFields = [
				'firstName',
				'lastName',
				'dateOfBirth',
				'patientExternalId',
				'state'
			];
			patientDemographicsFields.forEach(patientDemographicsField => {
				onChange(event, {
					name: patientDemographicsField,
					value: selectedCase.patient[patientDemographicsField]
				});
			});
		}
	}

	return (
		<Form.Field error={errors.fields.includes(FIELD_NAME)}>
			<label htmlFor={FIELD_NAME}>{FIELD_LABEL}</label>
			<Search
				id={FIELD_NAME}
				data-testid={PatientSearch.displayName}
				loading={isValidating}
				onResultSelect={handleResultSelect}
				onSearchChange={handleSearchChange}
				/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
				// @ts-ignore
				results={cases?.map(transformCasesToResults)}
				showNoResults={!isValidating}
				value={inputValue}
				fluid={true}
			/>
		</Form.Field>
	);
}

PatientSearch.FIELD_NAME = FIELD_NAME;
PatientSearch.displayName = `TextField-${FIELD_NAME}`;
PatientSearch.validate = validate;

export default PatientSearch;

import React, { useCallback, useEffect } from 'react';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import MicrophoneButton from './MicrophoneButton';
import { richTextEditorButtonStyles } from './RichTextEditorButtonCSS';
import { cx } from 'emotion';
import { useDictationContext } from 'contexts/DictationContext';

interface AwsDictationProps {
	onTranscribeStart: () => void;
	onTranscribeStop: () => void;
}

export default function AwsDictationButton({
	onTranscribeStart,
	onTranscribeStop
}: AwsDictationProps) {
	const [hasMicrophonePermissions, setHasMicrophonePermissions] = React.useState<boolean>(false);
	const [browserSupportsSpeechRecognition, setBrowserSupportsSpeechRecognition] =
		React.useState<boolean>(false);
	const [microphonePermissionStatus, setMicrophonePermissionStatus] =
		React.useState<PermissionStatus>();
	const { isListening } = useDictationContext();

	// Detect if the browser supports the apis needed to access the microphone
	// If the browser does support microphone recording, detect that the user has approved permissions
	useEffect(() => {
		// check to see if the browser allows mic access
		if (!navigator.mediaDevices.getUserMedia) {
			SitkaLogger.logMessage('Unsupported browser used', LogEventName.DICTATION);

			// maintain enabled/disabled state for the start and stop buttons
			setBrowserSupportsSpeechRecognition(false);
		} else {
			setBrowserSupportsSpeechRecognition(true);
		}
	}, []);

	const promptForMicrophonePermissions = useCallback(() => {
		if (browserSupportsSpeechRecognition) {
			navigator.mediaDevices
				.getUserMedia({ audio: true })
				.then(() => {
					setHasMicrophonePermissions(true);
				})
				.catch(() => {
					setHasMicrophonePermissions(false);
				});
		} else {
			setHasMicrophonePermissions(false);
		}
	}, [browserSupportsSpeechRecognition]);

	// Detect if the user has approved the microphone permissions
	const handleMicrophonePermissionState = React.useCallback(() => {
		if (microphonePermissionStatus?.state) {
			if (microphonePermissionStatus.state === 'granted') {
				setHasMicrophonePermissions(true);
			} else {
				setHasMicrophonePermissions(false);
				promptForMicrophonePermissions();
			}
		}
	}, [promptForMicrophonePermissions, microphonePermissionStatus?.state]);

	const detectMicrophonePermissions = React.useCallback(async () => {
		const result = await window.navigator.permissions.query({ name: 'microphone' });
		setMicrophonePermissionStatus(result);
	}, []);

	useEffect(() => {
		detectMicrophonePermissions();
	}, [detectMicrophonePermissions]);

	// Setup and cleanup microphone permission change handlers
	useEffect(() => {
		if (microphonePermissionStatus) {
			handleMicrophonePermissionState();
			microphonePermissionStatus.onchange = () => {
				handleMicrophonePermissionState();
			};
		}
		return () => {
			delete microphonePermissionStatus?.onchange;
		};
	}, [microphonePermissionStatus, handleMicrophonePermissionState]);

	if (!browserSupportsSpeechRecognition) {
		return <></>;
	}

	return (
		<div
			className={cx(
				{ [richTextEditorButtonStyles.buttonWrapper]: true },
				{ [richTextEditorButtonStyles.pulse]: isListening }
			)}
			data-testid="awsDictationButton">
			<MicrophoneButton
				hasMicrophonePermissions={hasMicrophonePermissions}
				onTranscribeStart={onTranscribeStart}
				onTranscribeStop={onTranscribeStop}
				data-testid="awsDictationButton"
			/>
		</div>
	);
}

import { FilterValueType } from 'components/Filters';
import {
	Accordion,
	Button,
	Checkbox,
	CheckboxProps,
	DatePicker,
	Icon,
	Popup,
	SemanticICONS
} from 'display';
import { css } from 'emotion';
import { Filter } from 'hooks/pagination';
import { cloneDeep, isArray } from 'lodash';
import * as React from 'react';
import { fontSize, mediaQueries } from 'styles';
import { FilterField } from './types';
import { Range } from 'thriftgen/api_types';

const filterPopupFontSize = fontSize[2];

const styles = {
	triggerButton: css`
		white-space: nowrap;
	`,
	applyFiltersDropdownItem: css`
		text-align: right !important;
		padding-top: 1em !important;
	`,
	accordionHeader: css`
		font-weight: bold;
	`,
	accordion: css`
		font-size: ${filterPopupFontSize} !important;
		.ui.checkbox label {
			font-size: ${filterPopupFontSize} !important;
		}
	`,
	accordionTitle: css`
		font-weight: bold;
		font-size: ${filterPopupFontSize} !important;
	`,
	accordionContent: css`
		padding-left: 1em !important;
		padding-right: 1em !important;
		padding-bottom: 1em !important;
		padding-top: 0 !important;
		max-height: 20vh;
		overflow-y: auto;
	`,
	accordionCheckboxContainer: css`
		${mediaQueries.desktop} {
			white-space: nowrap;
		}
	`,
	datePicker: css`
		margin-top: 0.5em !important;
		margin-bottom: 0.5em !important;
	`
};

interface FilterPopoverDropdownProps {
	filters: FilterField[];
	selectedFilters: Filter[];
	triggerIconName?: SemanticICONS;
	triggerText?: string;
	popoverTitleText?: string;
	displayPopoverTitle?: boolean;
	onChange: (filters: Filter[]) => void;
}

function FilterPopoverDropdown({
	filters,
	selectedFilters,
	triggerIconName,
	triggerText,
	popoverTitleText = 'Filters',
	displayPopoverTitle = true,
	onChange
}: FilterPopoverDropdownProps): JSX.Element {
	const [activeFilter, setActiveFilter] = React.useState(0);
	const [tempFilters, setTempFilters] = React.useState<Filter[]>([]);
	const [isOpen, setIsOpen] = React.useState(false);

	React.useEffect(() => {
		setTempFilters(selectedFilters);
	}, [selectedFilters]);

	function handleDateRangeFilterChange(
		attribute: string,
		value: number,
		rangeDirection: string
	): void {
		const rangeKey = rangeDirection === 'From' ? 'range_start' : 'range_end';
		let newTempFilters: Filter[] = cloneDeep(tempFilters);

		const currentFilter = newTempFilters.find(filter => filter.attribute === attribute);

		if (Number.isNaN(value)) {
			if (currentFilter) {
				currentFilter.value[rangeKey] = null;
				if (
					!(currentFilter.value as Range).range_start &&
					!(currentFilter.value as Range).range_end
				) {
					newTempFilters = newTempFilters.filter(
						filter => filter.attribute !== currentFilter?.attribute
					);
				}
			}
		} else {
			if (currentFilter) {
				currentFilter.value[rangeKey] = value;
			} else {
				const tempRange = new Range();
				tempRange[rangeKey] = value;
				const newFilter: Filter = {
					attribute,
					value: tempRange
				};
				newTempFilters.push(newFilter);
			}
		}

		setTempFilters(newTempFilters);
	}

	function handleFilterCheckboxChange(
		attribute: string,
		value: FilterValueType,
		isChecked: boolean
	): void {
		let newTempFilters: Filter[] = cloneDeep(tempFilters);

		const currentFilter = newTempFilters.find(filter => filter.attribute === attribute);

		if (isChecked) {
			if (currentFilter) {
				(currentFilter.value as Array<FilterValueType>).push(value);
			} else {
				const newFilter: Filter = {
					attribute,
					value: [value]
				};
				newTempFilters.push(newFilter);
			}
		} else {
			if (currentFilter) {
				currentFilter.value = (currentFilter.value as Array<string | number>).filter(
					filterValue => filterValue !== value
				);
				if (currentFilter.value.length === 0) {
					newTempFilters = newTempFilters.filter(
						filter => filter.attribute !== currentFilter.attribute
					);
				}
			}
		}

		setTempFilters(newTempFilters);
	}

	function isFilterSelected(attribute: string, value: FilterValueType): boolean {
		const currentFilter = tempFilters.find(filter => filter.attribute === attribute);
		if (currentFilter && isArray(currentFilter.value)) {
			return currentFilter.value.includes(value);
		}
		return false;
	}

	function getCurrentDateFilterValue(attribute: string, label: string): number | undefined {
		const currentRange = tempFilters.find(filter => filter.attribute === attribute)?.value;

		if (label === 'From') {
			return (currentRange as Range)?.range_start;
		} else if (label === 'To') {
			return (currentRange as Range)?.range_end;
		} else {
			return undefined;
		}
	}

	function renderFilter(filterField: FilterField, index: number): React.ReactNode {
		return (
			<div key={filterField.attribute}>
				<Accordion.Title
					key={`${filterField.attribute}-title`}
					onClick={() => setActiveFilter(index)}
					index={index}
					active={activeFilter === index}
					className={styles.accordionTitle}>
					<Icon name="dropdown" />
					{filterField.label}
				</Accordion.Title>
				<Accordion.Content
					key={`${filterField.attribute}-content`}
					active={activeFilter === index}
					className={styles.accordionContent}>
					{filterField.values.map(filterItem => {
						if (filterItem.value instanceof Range) {
							return (
								<>
									<DatePicker
										key={`request-date-${filterItem.label}`}
										label={filterItem.label}
										className={styles.datePicker}
										disableFuture={true}
										onChange={(newDate: number) => {
											handleDateRangeFilterChange(
												filterField.attribute,
												newDate,
												filterItem.label
											);
										}}
										defaultValue={getCurrentDateFilterValue(
											filterField.attribute,
											filterItem.label
										)}
									/>
									<br />
								</>
							);
						} else {
							return (
								<div
									key={`${filterItem.value}`}
									className={styles.accordionCheckboxContainer}>
									<Checkbox
										key={`checkbox-${filterItem.value}`}
										checked={isFilterSelected(
											filterField.attribute,
											filterItem.value
										)}
										label={filterItem.label}
										value={`${filterItem.value}`}
										onChange={(_: React.SyntheticEvent, data: CheckboxProps) =>
											handleFilterCheckboxChange(
												filterField.attribute,
												filterItem.value,
												!!data.checked
											)
										}
									/>
								</div>
							);
						}
					})}
				</Accordion.Content>
			</div>
		);
	}

	function handleOpen(): void {
		setIsOpen(true);
	}

	function handleClose(): void {
		setIsOpen(false);
	}

	function applyFilters(): void {
		onChange(cloneDeep(tempFilters));
		setIsOpen(false);
	}

	function renderPopupContent(): React.ReactNode {
		return (
			<>
				{displayPopoverTitle && (
					<div className={styles.accordionHeader}>{popoverTitleText}</div>
				)}
				<Accordion className={styles.accordion}>
					{filters.map(renderFilter)}
					<div className={styles.applyFiltersDropdownItem}>
						<Button.TextPrimary onClick={applyFilters} size={Button.Size.TINY}>
							Apply Filters
						</Button.TextPrimary>
					</div>
				</Accordion>
			</>
		);
	}

	function renderPopupTrigger(): React.ReactNode {
		const defaultTriggerIconName: SemanticICONS = 'filter';
		const defaultTriggerText = 'Filters';
		return (
			<Button.Text size={Button.Size.TINY} className={styles.triggerButton} floated="right">
				<Icon name={triggerIconName || defaultTriggerIconName} />{' '}
				{triggerText || defaultTriggerText}
			</Button.Text>
		);
	}

	return (
		<Popup
			data-testid="filterPopoverPopup"
			content={renderPopupContent()}
			on="click"
			positionFixed
			trigger={renderPopupTrigger()}
			position="bottom right"
			open={isOpen}
			onOpen={handleOpen}
			onClose={handleClose}
			flowing={true}
		/>
	);
}

export default FilterPopoverDropdown;

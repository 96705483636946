import { RecordAgainButton } from 'components/Buttons';
import { RecordingPreviewError, VideoNoAudioError, VideoTooShortError } from 'components/Messages';
import PreviewVideo from 'components/Recorder/PreviewVideo';
import { Button, Loader, VideoWithActions } from 'display';
import { getVideoBlobDurationInSeconds } from 'lib/getVideoBlobDurationInSeconds';
import * as React from 'react';
import { ReactEventHandler } from 'react';
import { VideoRecordingFatalError } from 'thriftgen/api_types';

enum PreviewState {
	ERROR = 'error',
	LOADING = 'loading',
	READY = 'ready',
	TOO_SHORT = 'too-short'
}

interface RecordingPreviewProps {
	audioError: Error | null;
	recordingPreviewAction: React.ReactNode;
	onRecordingReject: ReactEventHandler;
	onVideoError: (videoError: VideoRecordingFatalError, error?: Error | ErrorEvent) => void;
	video: Blob | null;
	hideVideoPreview?: boolean;
}

function RecordingPreview({
	audioError,
	onRecordingReject,
	onVideoError,
	recordingPreviewAction,
	video,
	hideVideoPreview
}: RecordingPreviewProps): JSX.Element {
	const [error, setError] = React.useState<Error | ErrorEvent>();
	const [state, setState] = React.useState<PreviewState>(PreviewState.LOADING);

	React.useEffect((): void => {
		if (hideVideoPreview) {
			setState(PreviewState.READY);
		} else if (video) {
			getVideoBlobDurationInSeconds(video)
				.then((duration: number): void => {
					if (duration >= 2) {
						setState(PreviewState.READY);
					} else {
						onVideoError(VideoRecordingFatalError.TOO_SHORT);
						setState(PreviewState.TOO_SHORT);
					}
				})
				.catch((reason: Error | ErrorEvent): void => {
					setError(reason);
					onVideoError(VideoRecordingFatalError.UNKNOWN, error);
					setState(PreviewState.ERROR);
				});
		} else {
			setState(PreviewState.LOADING);
		}
	}, [video, hideVideoPreview]);

	function renderContent(): JSX.Element {
		switch (state) {
			case PreviewState.ERROR:
				return <RecordingPreviewError message={error ? error.message : undefined} />;
			case PreviewState.LOADING:
				return <Loader active={true} data-testid="recordingPreview-loader" />;
			case PreviewState.READY:
				return hideVideoPreview ? <React.Fragment /> : <PreviewVideo video={video} />;
			case PreviewState.TOO_SHORT:
				return <VideoTooShortError />;
		}
	}

	return (
		<VideoWithActions>
			<VideoWithActions.Video>
				{audioError ? <VideoNoAudioError /> : renderContent()}
			</VideoWithActions.Video>
			<VideoWithActions.Actions>
				<Button.Group justifyContent="space-between">
					<RecordAgainButton onClick={onRecordingReject} />
					{recordingPreviewAction}
				</Button.Group>
			</VideoWithActions.Actions>
		</VideoWithActions>
	);
}

export { RecordingPreview, RecordingPreviewProps };

import {
	convertTextToEditorState,
	Form,
	RichTextEditor,
	RichTextEditorContentValues
} from 'display';
import { EditorState } from 'draft-js';
import { css } from 'emotion';
import * as React from 'react';
import { colors } from 'styles';
import { DataFormFieldComponentProps } from '../DataFormTypes';

const FIELD_NAME = 'textResponse';
const FIELD_LABEL = 'Written Response';
const PLACEHOLDER = 'Add your message';

type TextResponseProps = DataFormFieldComponentProps<TextResponseValue>;
type TextResponseValue = string | undefined;

function TextResponse({ errors, onChange, data }: TextResponseProps): JSX.Element {
	const value = data[FIELD_NAME];

	const styles = {
		label: css`
			color: ${colors.primary.base} !important;
			font-size: 15px !important;
		`
	};

	const [richTextEditorContentValues, setRichTextEditorContentValues] =
		React.useState<RichTextEditorContentValues>({
			markdown: value || '',
			editorState: value ? convertTextToEditorState(value) : EditorState.createEmpty()
		});

	function hasError(): boolean {
		return errors.fields.includes(FIELD_NAME);
	}

	function onRichChangeHandler(content: RichTextEditorContentValues): void {
		setRichTextEditorContentValues(content);
		onChange(null, {
			name: FIELD_NAME,
			value: content.markdown
		});
	}

	return (
		<Form.Field error={hasError()}>
			<label className={styles.label}>{FIELD_LABEL}</label>
			<RichTextEditor
				placeholder={PLACEHOLDER}
				onChange={onRichChangeHandler}
				richTextEditorContentValues={richTextEditorContentValues}
				hasError={hasError()}
			/>
		</Form.Field>
	);
}

function validate(data: TextResponseProps['data'], required: boolean) {
	const nameField = data[FIELD_NAME];

	if (required && !nameField) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please enter a text response.']
		};
	}

	return null;
}

TextResponse.FIELD_NAME = FIELD_NAME;
TextResponse.validate = validate;

export default TextResponse;

import { CancelButton, SaveButton } from 'components/Buttons';
import { Button, ButtonProps, Divider } from 'display';
import { objectSnakeToCamel } from 'lib/objectConverter';
import * as React from 'react';
import { User, UserInfo } from 'thriftgen/api_types';
import DataForm from './DataForm';
import { DataFormChangeData, DataFormErrors } from './DataFormTypes';
import { CombinedDateOfBirth, Email } from './Fields';
import { getErrors, getFieldsAndSubfieldNames, getNoErrors } from './Fields/helpers';
import FormErrors from './FormErrors';

type FormData = Pick<UserInfo, 'date_of_birth' | 'email'>;

interface PatientContactInformationFormProps {
	children?: React.ReactNode;
	isEditable: boolean;
	onCancel: () => void;
	onDone: (userUpdates: Pick<UserInfo, 'date_of_birth' | 'email'> | null) => Promise<void>;
	patient: User;
	SubmitControl?: React.ComponentType<ButtonProps>;
}

const FIELDS = [CombinedDateOfBirth, Email];
const ALL_FIELD_NAMES = getFieldsAndSubfieldNames(FIELDS);

/**
 * Plucks the data that will be used in the form from the provided raw data and returns it.
 *
 * @param {object} data the data passed in to this form
 * @return {object} the data specific to the fields on the form
 */
function extractFormFieldsFromRawData(data: User): FormData {
	const camelData = objectSnakeToCamel(data);

	return ALL_FIELD_NAMES.reduce((formData: FormData, field: keyof User) => {
		if (camelData[field] !== undefined) {
			formData[field] = camelData[field];
		}

		return formData;
	}, {});
}

function PatientContactInformationForm({
	children = null,
	isEditable,
	onCancel,
	onDone,
	patient,
	SubmitControl = SaveButton
}: PatientContactInformationFormProps): JSX.Element {
	const initialFormData: FormData = extractFormFieldsFromRawData(patient);

	const [formData, setFormData] = React.useState<FormData>(initialFormData);
	const [formErrors, setFormErrors] = React.useState<DataFormErrors>(getNoErrors());

	React.useEffect((): void => {
		setFormData(extractFormFieldsFromRawData(patient));
	}, [patient]);

	function onCancelClick(): void {
		setFormData(initialFormData);
		setFormErrors(getNoErrors());

		onCancel();
	}

	function onFormChange(
		_: React.SyntheticEvent,
		{ name, value }: DataFormChangeData<FormData>
	): void {
		setFormData({
			...formData,
			[name]: value
		});
	}

	function submit(): Promise<void> {
		if (!isEditable) {
			return onDone(null);
		}

		const errors = getErrors(FIELDS, ALL_FIELD_NAMES, formData);

		if (errors.fields.length > 0) {
			return Promise.resolve().then(() => {
				setFormErrors(errors);
			});
		}

		return onDone(formData);
	}

	const formProps = {
		data: formData,
		errors: formErrors,
		fields: FIELDS,
		editableFields: isEditable ? ALL_FIELD_NAMES : [],
		onChange: onFormChange
	};

	return (
		<React.Fragment>
			<DataForm {...formProps} />
			{formErrors.fields.length > 0 && (
				<FormErrors fields={formErrors.fields} messages={formErrors.messages} />
			)}
			{children}
			<Divider section={true} />
			<Button.Group>
				<CancelButton onClick={onCancelClick} />
				<SubmitControl onClickAsync={submit} />
			</Button.Group>
		</React.Fragment>
	);
}

export { PatientContactInformationForm, PatientContactInformationFormProps };

import * as React from 'react';

interface EventLog {
	timestamp: string;
	message: string;
}

type UseWindowEventLogResult = (message: string) => void;

// This hook allows you to create an event log that is retrievable
// via the window.Sitka namespace. This can be helpful when verifying
// functionality and/or troubleshooting a feature. The hook becomes
// a noop in production.
//
// To register logs, instantiate the hook with a custom namespace
// and use the returned function.
//
// const log = useWindowEventLog('sessionTimeouts');
// log('Session renewed');
//
// To retrieve the event log, refer to the custom namespace
// under window.Sitka.debug in the browser's console.
//
// window.Sitka.debug.sessionTimeouts.printLog();

function useWindowEventLog(namespace: string): UseWindowEventLogResult {
	const [events, setEvent] = React.useState<EventLog[]>([]);
	const isProduction = process.env.REACT_APP_SITKA_ENV === 'PROD';

	const log = React.useCallback((message: string) => {
		if (isProduction) {
			return;
		}

		setEvent(current => [...current, { timestamp: new Date().toLocaleTimeString(), message }]);
	}, []);

	React.useEffect(() => {
		if (isProduction) {
			return;
		}

		window.Sitka.debug[namespace] = {
			printLog: function () {
				//eslint-disable-next-line no-console
				console.log('No events logged yet.');
			}
		};

		return () => {
			delete window.Sitka.debug[namespace];
		};
	}, [isProduction, namespace]);

	React.useEffect(() => {
		if (isProduction || events.length === 0) {
			return;
		}

		window.Sitka.debug[namespace].printLog = function () {
			//eslint-disable-next-line no-console
			console.table(events);
		};
	}, [events, isProduction, namespace]);

	return log;
}

export { useWindowEventLog, UseWindowEventLogResult };

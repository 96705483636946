import * as React from 'react';
import {
	ConsultSummariesContext,
	ConsultSummariesContextData,
	ConsultSummariesContextMutators
} from 'contexts/ConsultSummariesContext';

interface ConsumerProps {
	data: ConsultSummariesContextData;
	mutators: ConsultSummariesContextMutators;
}

type ConsultSummariesContextProviderProps = ConsumerProps & {
	children: React.ReactNode;
};

function ConsultSummariesContextProvider({
	data,
	mutators,
	children
}: ConsultSummariesContextProviderProps): JSX.Element {
	const value = React.useMemo(
		() => ({
			...data,
			...mutators
		}),
		[data, mutators]
	);

	return (
		<ConsultSummariesContext.Provider value={value}>
			{children}
		</ConsultSummariesContext.Provider>
	);
}

export default ConsultSummariesContextProvider;

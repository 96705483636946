import * as React from 'react';
import { getUserFullNameAndTitle } from 'lib/userHelpers';

import User from 'components/admin/User/User';
import { Text } from 'display';

interface OriginalUserSelectionLabelProps {
	value: string | undefined;
}

function OriginalUserSelectionLabel({ value }: OriginalUserSelectionLabelProps): JSX.Element {
	const [originalSelectionId] = React.useState(value);
	let selection: React.ReactNode = 'None';

	if (originalSelectionId) {
		selection = <User userId={originalSelectionId} textFunc={getUserFullNameAndTitle} />;
	}

	return (
		<Text>
			Current (to be replaced): <strong>{selection}</strong>
		</Text>
	);
}

export default OriginalUserSelectionLabel;

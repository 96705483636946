import * as React from 'react';
import { DocumentDataAsset, isDocumentDataViewable } from 'lib/documentAssets';
import { isVideo } from 'lib/consultDataHelpers';

import { useDocumentLoading } from 'hooks';
import { ReportEventParams } from 'api/common';

import { PdfViewer } from 'components/FileViewers';
import { DocumentViewerNavigation } from 'components/DocumentViewerNavigation';
import {
	ConsultDataNotFound,
	DocumentNotViewableMessage,
	DocumentUnavailableMessage
} from 'components/Messages';

interface ViewerProps {
	documents: DocumentDataAsset[];
	defaultDocument?: DocumentDataAsset;
	onDocumentChange: (selection: DocumentDataAsset) => void;
	reportEvent: (args: ReportEventParams) => Promise<void>;
}

interface LoadingState {
	inProgress: boolean;
	timeout: null | ReturnType<typeof setTimeout>;
}

const DISABLED_CONTROLS_TIMEOUT = 5000;

function getInitialLoadingState(defaultDocument: ViewerProps['defaultDocument']): LoadingState {
	if (defaultDocument) {
		return {
			inProgress: isDocumentDataViewable(defaultDocument) && defaultDocument.isReady,
			timeout: null
		};
	}

	return {
		inProgress: false,
		timeout: null
	};
}

function Viewer({
	documents,
	defaultDocument,
	onDocumentChange,
	reportEvent
}: ViewerProps): JSX.Element {
	const { getDocumentSource } = useDocumentLoading(documents, {
		skipTiffImagePreload: true,
		skipVideoPreload: true
	});
	const [activeDocument, setActiveDocument] = React.useState<DocumentDataAsset | undefined>(
		defaultDocument
	);
	const [loading, setLoading] = React.useState<LoadingState>(
		getInitialLoadingState(defaultDocument)
	);

	React.useMemo(() => {
		setActiveDocument(defaultDocument);
	}, [defaultDocument]);

	const viewerData = React.useMemo(() => {
		if (!activeDocument) {
			return null;
		}
		const source = getDocumentSource(activeDocument.id);

		if (!source) {
			return null;
		}

		return {
			name: activeDocument.description,
			id: activeDocument.id,
			isVideo: isVideo(activeDocument),
			source,
			thumbnail: activeDocument.thumbnail_url
		};
	}, [activeDocument]);

	function removeAnyTimeout(): void {
		if (loading.timeout) {
			clearTimeout(loading.timeout);
		}
	}

	React.useEffect(() => {
		if (loading.inProgress) {
			setLoading({
				...loading,
				timeout: setTimeout(unsetLoading, DISABLED_CONTROLS_TIMEOUT)
			});
		}

		return removeAnyTimeout;
	}, []);

	function onDocumentSelection(document: DocumentDataAsset): void {
		removeAnyTimeout();

		setLoading({
			inProgress: true,
			timeout: setTimeout(unsetLoading, DISABLED_CONTROLS_TIMEOUT)
		});

		setActiveDocument(document);
		onDocumentChange(document);
	}

	function unsetLoading(): void {
		removeAnyTimeout();

		setLoading({
			inProgress: false,
			timeout: null
		});
	}

	function renderContent(): JSX.Element {
		if (activeDocument === undefined) {
			return <ConsultDataNotFound />;
		}

		if (isDocumentDataViewable(activeDocument)) {
			if (viewerData === null) {
				return <DocumentUnavailableMessage />;
			}

			return (
				<PdfViewer
					data={viewerData}
					onDocumentLoadError={unsetLoading}
					onDocumentLoaded={unsetLoading}
					reportEvent={reportEvent}
				/>
			);
		}

		return <DocumentNotViewableMessage />;
	}

	return (
		<DocumentViewerNavigation
			documents={documents}
			disabled={loading.inProgress}
			currentDocument={activeDocument}
			onDocumentChange={onDocumentSelection}>
			{renderContent()}
		</DocumentViewerNavigation>
	);
}

export default Viewer;

import * as H from 'history';
import { disablePendo, initializePendo, isPendoEnabled, loadPendo, reenablePendo } from 'lib/pendo';
import { getUniquePartnerAssociations } from 'lib/userHelpers';
import * as React from 'react';
import { connect } from 'react-redux';
import { matchPath } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import RouteUrls from 'routing/RouteUrls';
import { selectAuthUser, selectHasAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { User } from 'thriftgen/api_types';

interface StoreProps {
	actor: User | null;
}

enum Status {
	IDLE,
	LOADING,
	INITIALIZED
}

function pathnameIsExcluded(pathname: string, excludedPaths: string[]): boolean {
	return excludedPaths
		.map((excludedPath: string) => {
			return Boolean(
				matchPath(pathname, {
					path: excludedPath
				})
			);
		})
		.includes(true);
}

function Pendo({ actor }: StoreProps): null {
	const [status, setStatus] = React.useState<Status>(Status.IDLE);
	const isExcludedRoute = RouteUrls.pathsWithTelemetryDisabled
		.map(useRouteMatch)
		.map(Boolean)
		.includes(true);
	const history = useHistory();

	React.useEffect(() => {
		if (status !== Status.INITIALIZED) {
			return;
		}

		return history.listen((incomingLocation: H.Location): void => {
			if (
				pathnameIsExcluded(incomingLocation.pathname, RouteUrls.pathsWithTelemetryDisabled)
			) {
				disablePendo();
			} else if (!isPendoEnabled()) {
				reenablePendo();
			}
		});
	}, [status, history]);

	React.useEffect(() => {
		if (status === Status.IDLE && actor && !isExcludedRoute) {
			// We only load and initialize once per page load. Auth user
			// cannot change without a page reload so no need to re-initialize
			// at runtime. We also withhold initialization until a non-excluded page
			// is loaded.
			setStatus(Status.LOADING);

			loadPendo();

			const userPartnerIds = getUniquePartnerAssociations(actor);
			const visitorInfo = {
				id: actor.user_id,
				isSitkaMedicalAssociate: actor.is_sitka_medical_associate,
				isSitkaOperations: actor.is_sitka_operations
			};

			const accountInfo = {
				id: userPartnerIds.length === 1 ? userPartnerIds[0] : undefined
			};

			initializePendo(
				{
					visitor: visitorInfo,
					account: accountInfo
				},
				() => setStatus(Status.INITIALIZED)
			);
		}
	}, [status, actor, isExcludedRoute]);

	return null;
}

const mapStoreToProps = (state: AppState): StoreProps => ({
	actor: selectHasAuthUser(state) ? selectAuthUser(state) : null
});

export default connect(mapStoreToProps)(Pendo);

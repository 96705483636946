import { localStorage as LocalStorage } from 'lib/Storage';
import { SitkaLogger } from './sitkaLogger';

export enum Feature {
	CWA_597_CLINICAL_REVIEW = 'CWA_597_CLINICAL_REVIEW',
	CWA_1051_IMPLEMENT_DICTATION_SPEECH_TO_TEXT = 'CWA_1051_IMPLEMENT_DICTATION_SPEECH_TO_TEXT',
	CWA_1051_ENABLE_DICTATION_LOGGING = 'CWA_1051_ENABLE_DICTATION_LOGGING',
	CWA_1098_OVERRIDE_FILE_UPLOAD_MIME_TYPE_CHECK = 'CWA_1098_OVERRIDE_FILE_UPLOAD_MIME_TYPE_CHECK',
	CWA_1378_SMA_LICENSES = 'CWA_1378_SMA_LICENSES',
	CWA_1355_SMA_SCHEDULE = 'CWA_1355_SMA_SCHEDULE',
	CWA_1384_AWS_SDK_UPGRADE = 'CWA_1384_AWS_SDK_UPGRADE',
	CWA_1537_ATHENA_ATTACHMENT_HELP = 'CWA_1537_ATHENA_ATTACHMENT_HELP',
	CWA_1567_SMA_BIO_POPUP = 'CWA_1567_SMA_BIO_POPUP'
}

const setFeatureEnabled = (feature: Feature, value: boolean) => {
	LocalStorage.set(feature, value);
	SitkaLogger.logMessage(`Feature ${feature} is ${value ? 'enabled' : 'disabled'}`);
};

const isFeatureEnabled = (feature: Feature): boolean => {
	if (process.env.REACT_APP_DISABLE_UNLEASH === 'true') {
		return true;
	}
	return !!LocalStorage.get(feature);
};

export { setFeatureEnabled, isFeatureEnabled };

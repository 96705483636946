import * as React from 'react';
import { css } from 'emotion';
import { isPromise } from 'lib/isObject';

import { Dropdown, Loader, Text } from 'display';

interface ActionMenuProps {
	children: React.ReactNode;
	'data-testid'?: string;
}

interface ActionMenuItemProps {
	loading?: boolean;
	onClick: () => Promise<unknown> | void;
	text: string;
}

interface MenuContextValue {
	closeMenu: () => void;
}

const style = {
	loader: css`
		margin-left: 0.5em !important;
	`
};

const MenuContext = React.createContext<MenuContextValue | null>(null);
const useMenuContext = (): MenuContextValue => {
	const context = React.useContext(MenuContext);
	if (!context) {
		throw new Error('<ActionMenu.Item /> must be used within <ActionMenu />');
	}

	return context;
};

function ActionMenu({ children, 'data-testid': dataTestId }: ActionMenuProps): JSX.Element {
	const [isOpen, setMenuOpen] = React.useState<boolean>(false);

	function closeMenu(): void {
		setMenuOpen(false);
	}

	function toggleMenu(event: React.SyntheticEvent): void {
		// Only toggle when the clicked element is the menu element
		if (event.target === event.currentTarget) {
			setMenuOpen(!isOpen);
		}
	}

	return (
		<MenuContext.Provider value={{ closeMenu }}>
			<Dropdown
				icon="ellipsis vertical"
				open={isOpen}
				onClick={toggleMenu}
				onBlur={closeMenu}
				pointing="top right"
				title="Actions"
				data-testid={dataTestId}>
				<Dropdown.Menu>{children}</Dropdown.Menu>
			</Dropdown>
		</MenuContext.Provider>
	);
}

function ActionsMenuItem({ loading = false, onClick, text }: ActionMenuItemProps): JSX.Element {
	const { closeMenu } = useMenuContext();

	function onItemClick(): Promise<unknown> | void {
		const result = onClick();

		if (isPromise(result)) {
			return result.then(closeMenu, closeMenu);
		} else {
			closeMenu();
		}
	}

	return (
		<Dropdown.Item onClick={onItemClick} disabled={loading}>
			<Text size={Text.Size.T4}>{text}</Text>
			{loading && (
				<Loader
					size="tiny"
					active={true}
					inline={true}
					className={style.loader}
					data-testid={`${text}-loader`}
				/>
			)}
		</Dropdown.Item>
	);
}

ActionMenu.Item = ActionsMenuItem;

export default ActionMenu;

import ConsultationRecorder from 'components/Recorders/ConsultationRecorder';
import { StreamUploader } from 'lib/Uploaders';
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DataFormFieldComponentProps } from '../DataFormTypes';
import UploadContextProvider from 'components/ContextProviders/UploadContextProvider';
import { Form, Paragraph, Segment } from 'display';
import Uploader from 'components/Uploader/Uploader';

const FIELD_NAME = 'recordVideo';

type RecordVideoProps = DataFormFieldComponentProps<RecordVideoValue>;
type RecordVideoValue = string[] | undefined;

function RecordVideo({ errors, onChange }: RecordVideoProps): JSX.Element {
	const [isUploading, setIsUploading] = React.useState<boolean>(false);
	const [uploader, setUploader] = React.useState<PendingAny>(new StreamUploader());
	const [recordingId, setRecordingId] = React.useState<string | null>(uuidv4());
	const [video, setVideo] = React.useState<Blob | null>(null);

	function resetAllStates(): void {
		setUploader(new StreamUploader());
		setIsUploading(false);
		setRecordingId(uuidv4());
		setVideo(null);
	}

	function hasError(): boolean {
		return errors.fields.includes(FIELD_NAME);
	}

	function canRecordScreen(): boolean {
		return true;
	}

	function onCompleteHandler(video: Blob): void {
		setIsUploading(true);
		setVideo(video);

		uploader.addUploadCompleteListener((uploadKeys: string[]) => {
			setIsUploading(false);
			onChange(null, {
				name: FIELD_NAME,
				value: uploadKeys
			});
		});
	}

	function onRejectHandler(): void {
		resetAllStates();
	}

	function renderUploader() {
		return (
			<Segment basic={true} textAlign="center">
				<Paragraph size={Paragraph.Size.T1} weight={Paragraph.Weight.BOLD}>
					Uploading
				</Paragraph>
				<Uploader uploader={uploader} uploadError={uploader.uploadError} />
			</Segment>
		);
	}

	function renderVideoRecorder() {
		return (
			<Form.Field error={hasError()}>
				<UploadContextProvider value={uploader}>
					<ConsultationRecorder
						canRecordScreen={canRecordScreen}
						video={video}
						onRecordingComplete={onCompleteHandler}
						onRecordingReject={onRejectHandler}
						recordingId={recordingId}
					/>
				</UploadContextProvider>
			</Form.Field>
		);
	}

	if (isUploading) {
		return renderUploader();
	}
	return renderVideoRecorder();
}

function validate(data: RecordVideoProps['data'], required: boolean) {
	const nameField = data[FIELD_NAME];

	if (required && !nameField) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please record a video.']
		};
	}

	return null;
}

RecordVideo.FIELD_NAME = FIELD_NAME;
RecordVideo.validate = validate;

export default RecordVideo;

import { useOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { IntegrationType } from 'thriftgen/api_types';
import { SessionIntegrationSettings } from './types';

function useSessionIntegrationSettings(): SessionIntegrationSettings | undefined {
	const { authState } = useOktaAuth();

	const isInIframe = React.useMemo((): boolean => {
		try {
			return window.self !== window.top;
		} catch (e) {
			return false;
		}
	}, []);

	const forceReauthentication = React.useMemo((): boolean => {
		const claims = authState?.idToken?.claims;
		if (claims) {
			const { idp } = claims;
			const forceReauthenticationIdpIds = (
				process.env.REACT_APP_FORCE_REAUTHENTICATION_IDP_IDS || ''
			).split(',');
			return forceReauthenticationIdpIds.includes(idp as string);
		}
		return false;
	}, [authState?.idToken?.claims]);

	// If a user logs in using the known Athena IDP AND has a patient id included in their
	// token, we will enable the Athena SSO integration and redirect the user to the consult
	// request form for the patient in their token.  If the user does not have a patient ID
	// in scope, they will be taken to the normal application landing page.
	const isAthenaSSOLogin = React.useCallback((): boolean => {
		const claims = authState?.idToken?.claims;
		if (claims) {
			const { idp, patient_id } = claims;
			return (
				idp === process.env.REACT_APP_ATHENA_SSO_INTEGRATION_IDP_ID &&
				!!patient_id &&
				!isNaN(+patient_id)
			);
		}
		return false;
	}, [authState?.idToken?.claims]);

	const sessionIntegrationSettings: SessionIntegrationSettings = React.useMemo(() => {
		let settings: SessionIntegrationSettings = {
			isInIframe: isInIframe,
			forceReauthentication
		};
		if (isAthenaSSOLogin()) {
			settings = {
				...settings,
				integrationType: IntegrationType.ATHENA,
				athenaSettings: {
					patientId: authState?.idToken?.claims.patient_id as string,
					departmentId: authState?.idToken?.claims.department_id as string,
					practiceId: authState?.idToken?.claims.practice_id as string
				}
			};
		}
		return settings;
	}, [authState?.idToken?.claims, isAthenaSSOLogin, isInIframe, forceReauthentication]);

	return sessionIntegrationSettings;
}

export { useSessionIntegrationSettings };

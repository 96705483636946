import { ReportEventParams } from 'api/common';
import { PdfViewerLoadError } from 'components/Messages';
import { Loader } from 'display';
import { useVideoWatchReporting } from 'hooks';
import * as React from 'react';
import { EventType, ObjectType } from 'thriftgen/api_types';
import { PdfViewerAsset } from './types';
import useWebViewer from './useWebViewer';

interface PdfViewerProps {
	data: PdfViewerAsset;
	onDocumentLoaded: () => void;
	onDocumentLoadError: () => void;
	reportEvent: (args: ReportEventParams) => Promise<void>;
}

function PDFViewer({
	data,
	onDocumentLoadError,
	onDocumentLoaded,
	reportEvent
}: PdfViewerProps): JSX.Element {
	const handleLoadError = React.useCallback(() => {
		onDocumentLoadError();

		if (!data.isVideo) {
			reportEvent({
				event: { eventType: EventType.DATA_VIEW_FAILED },
				objectId: data.id,
				objectType: ObjectType.CONSULT_DATA
			});
		}
	}, [data.id]);

	const handleDocumentLoad = React.useCallback(() => {
		onDocumentLoaded();

		if (!data.isVideo) {
			reportEvent({
				event: { eventType: EventType.DATA_VIEWED },
				objectId: data.id,
				objectType: ObjectType.CONSULT_DATA
			});
		}
	}, [data.id]);

	const videoEventsHandlers = useVideoWatchReporting({
		objectId: data.id,
		objectType: ObjectType.CONSULT_DATA
	});

	const { container, error, isLoading } = useWebViewer({
		document: data,
		onDocumentLoaded: handleDocumentLoad,
		onDocumentLoadError: handleLoadError,
		videoEventsHandlers
	});
	//if the container is not present at time of doc load, PDFTron breaks
	return (
		<React.Fragment>
			<div
				ref={container}
				style={
					error
						? { width: '0', visibility: 'hidden' }
						: { width: '100%', visibility: 'visible' }
				}>
				{isLoading && <Loader active={true} />}
			</div>
			{error && <PdfViewerLoadError documentName={data.name} />}
		</React.Fragment>
	);
}

export default PDFViewer;

import { USER_FILTERS } from 'api/customFilters';
import userColumns from 'components/DataTable/UserTable/columns';
import * as React from 'react';
import { Consult } from 'thriftgen/api_types';
import OriginalUserSelectionLabel from './OriginalUserSelectionLabel';
import { UserSelectProps } from './types';
import UserSelect from './UserSelect';

type ConsultInitiatorSelectProps = Pick<UserSelectProps, 'name' | 'onChange'> & {
	consultId: Consult['consult_id'];
	value: string;
};

const COLUMNS = [userColumns.buildPhoneNumber(), userColumns.buildEmail()];

function ConsultInitiatorSelect({ consultId, ...props }: ConsultInitiatorSelectProps): JSX.Element {
	const requestFilters = React.useMemo(
		() => [
			{
				attribute: USER_FILTERS.REQUESTOR_FOR_CONSULT,
				value: consultId
			}
		],
		[consultId]
	);

	return (
		<React.Fragment>
			<OriginalUserSelectionLabel value={props.value} />
			<UserSelect columns={COLUMNS} requestFilters={requestFilters} {...props} />
		</React.Fragment>
	);
}

export default ConsultInitiatorSelect;

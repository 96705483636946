import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { dispatchEvent, SitkaEvent } from 'lib/events';

import { useInterval } from 'hooks/useInterval';

import { FileInput, Loader } from 'display';

import { RecordingOption } from './types';

const TEN_SECONDS = 10000;

enum Status {
	START,
	LOADING,
	READY
}

interface RecorderFallbackProps {
	onVideoSelect: (file: File, recordingOption: RecordingOption) => void;
}

function RecorderFallback({ onVideoSelect }: RecorderFallbackProps): JSX.Element {
	const [isRecording, setIsRecording] = React.useState<boolean>(false);
	const [status, setStatus] = React.useState<Status>(Status.START);
	const { oktaAuth } = useOktaAuth();
	const renewToken = () => oktaAuth.tokenManager.renew('accessToken');

	React.useEffect(() => {
		if (status === Status.START) {
			setStatus(Status.LOADING);
			renewToken()
				.then((): void => {
					setStatus(Status.READY);
				})
				.catch((): void => {
					setStatus(Status.READY);
				});
		}
	}, [oktaAuth.tokenManager, status]);

	React.useEffect(() => {
		function stopDispatchOnFocus() {
			if (isRecording) {
				setIsRecording(false);
			}

			window.removeEventListener('focus', stopDispatchOnFocus);
		}

		if (isRecording) {
			window.addEventListener('focus', stopDispatchOnFocus);
		}

		return () => {
			if (isRecording) {
				window.removeEventListener('focus', stopDispatchOnFocus);
			}
		};
	}, [isRecording]);

	function setRecording() {
		setIsRecording(true);
	}

	function onFileChange(files: File[]) {
		renewToken();
		setIsRecording(false);
		if (files.length > 0) {
			onVideoSelect(files[0], RecordingOption.CAMERA_ONLY);
		}
	}

	useInterval(
		(): void => {
			dispatchEvent(SitkaEvent.RECORDING);
		},
		isRecording ? TEN_SECONDS : null
	);

	switch (status) {
		case Status.READY:
			return (
				<FileInput
					accept=".mov,.mp4,.mpeg4"
					capture="capture"
					data-testid="recorderFallback"
					id="recorderFallback"
					label="Launch my camera"
					multiple={false}
					onClick={setRecording}
					onFileChange={onFileChange}
				/>
			);
		case Status.LOADING:
		case Status.START:
			return <Loader active={true} inline="centered" data-testid="recorderFallbackLoader" />;
	}
}

export default RecorderFallback;

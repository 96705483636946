// TODO - account for expiry times
import secureLocalStorage from 'react-secure-storage';

type StorageTypes =
	| WindowLocalStorage['localStorage']
	| WindowSessionStorage['sessionStorage']
	| (typeof secureLocalStorage & { length?: never; key?: never });

type StorageValue = any;

type StorageEntries = {
	[key: string]: StorageValue;
};

interface KeyValueStore {
	get: (key: string) => StorageValue;
	set: (key: string, data: StorageValue) => void;
	clear: () => void;
	remove: (key: string) => void;
}

class Storage implements KeyValueStore {
	private storage: StorageTypes;

	constructor(storage: StorageTypes) {
		this.storage = storage;
	}

	private _shouldStringifyStorageValue(key: string) {
		return (
			!!process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX &&
			!key.startsWith(process.env.REACT_APP_SECURE_LOCAL_STORAGE_PREFIX)
		);
	}

	public get(key: string): StorageValue {
		const item = this.storage.getItem(key);

		if (item !== null && typeof item === 'string') {
			if (this._shouldStringifyStorageValue(key)) {
				return JSON.parse(item);
			} else {
				return item;
			}
		}

		return null;
	}

	public set(key: string, data: StorageValue): void {
		let value = data;
		if (this._shouldStringifyStorageValue(key)) {
			value = JSON.stringify(data);
		}
		this.storage.setItem(key, value);
	}

	public remove(key: string): void {
		this.storage.removeItem(key);
	}

	public clear(): void {
		this.storage.clear();
	}

	public length(): number {
		if (this.storage.length !== undefined) {
			return this.storage.length;
		} else {
			throw new Error('Length not supported by this storage type');
		}
	}

	public key(keyId: number): string | null {
		if (this.storage.key) {
			return this.storage.key(keyId);
		} else {
			throw new Error('Key not supported by this storage type');
		}
	}
}

export const localStorage = new Storage(window.localStorage);
export const sessionStorage = new Storage(window.sessionStorage);
export const secureStorage = new Storage(secureLocalStorage);

export enum StorageKeys {
	RELOAD_VIDEO_CALL = 'reloadVideoCall',
	USER = 'user',
	AUTH_SESSION_ID = 'auth_session_id'
}

export { KeyValueStore, StorageEntries, StorageValue };

import { AlertProps } from '@mui/material/Alert';
import { OpenSnackbarProps, SnackbarProvider } from 'contexts/SnackbarContext';
import { SnackbarAlert } from 'display/MaterialUI';
import * as React from 'react';

interface SnackbarContextProviderProps {
	children: React.ReactNode;
}

function SnackbarContextProvider({ children }: SnackbarContextProviderProps) {
	const [isOpen, setIsOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [alertSeverity, setAlertSeverity] = React.useState<AlertProps['severity']>('success');

	function openSnackbar({ message, severity }: OpenSnackbarProps): void {
		setSnackbarMessage(message);
		setAlertSeverity(severity);
		setIsOpen(true);
	}

	function onClose(_event: React.SyntheticEvent, reason?: string): void {
		if (reason === 'clickaway') {
			return;
		}

		setIsOpen(false);
	}

	return (
		<SnackbarProvider value={{ openSnackbar }}>
			{children}
			<SnackbarAlert
				onClose={onClose}
				isOpen={isOpen}
				message={snackbarMessage}
				severity={alertSeverity}
			/>
		</SnackbarProvider>
	);
}

export default SnackbarContextProvider;

import * as React from 'react';
import { connect } from 'react-redux';
import { identifyVisitor, loadZendesk } from 'lib/zendesk';
import { getUserFullNameAndTitle, isUserPatient } from 'lib/userHelpers';

import { selectAuthUser, selectHasAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { SitkaLogger } from 'lib/sitkaLogger';

interface StoreProps {
	actor: User | null;
}

enum Status {
	IDLE,
	LOADED
}

function Zendesk({ actor }: StoreProps): null {
	const [status, setStatus] = React.useState<Status>(Status.IDLE);

	React.useEffect((): void => {
		if (status === Status.LOADED || actor === null || isUserPatient(actor)) {
			return;
		}

		setStatus(Status.LOADED);
		loadZendesk()
			.then((): void => {
				identifyVisitor({
					name: getUserFullNameAndTitle(actor),
					email: actor.email
				});
			})
			.catch(error => SitkaLogger.logMessage(error));
	}, [status, actor]);

	return null;
}

const mapStoreToProps = (state: AppState): StoreProps => ({
	actor: selectHasAuthUser(state) ? selectAuthUser(state) : null
});

export default connect(mapStoreToProps)(Zendesk);

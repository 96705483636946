import { reportEvent } from 'api/common';
import throttle from 'lib/throttle';
import * as React from 'react';
import { ConsultData, ConversationMessage, EventType, ObjectType } from 'thriftgen/api_types';
import { v4 as uuidv4 } from 'uuid';
import { SUPPORTED_OBJECTS, TEN_SECONDS } from './constants';

interface UseVideoWatchReportingOptions {
	objectId: ConsultData['consult_data_id'] | ConversationMessage['message_id'];
	objectType: ObjectType;
}

type VideoEvent = React.SyntheticEvent<HTMLVideoElement> | DOMElementEvent<HTMLVideoElement>;
type VideoEventHandler = (event: VideoEvent) => void;

interface UseVideoWatchReporting {
	onEnded: VideoEventHandler;
	onError: VideoEventHandler;
	onPause: VideoEventHandler;
	onPlay: VideoEventHandler;
	onSeeked: VideoEventHandler;
	onTimeUpdate: VideoEventHandler;
}

interface EventAction {
	[key: string]: number | string;
}

function useVideoWatchReporting({
	objectId,
	objectType
}: UseVideoWatchReportingOptions): UseVideoWatchReporting {
	// playbackId is used to group all VIDEO_* events as part of the same
	// playback session. The DATA_VIEWED_* events are intentionally
	// excluded from this treatment.
	const playbackId = React.useMemo(() => uuidv4(), [objectId]);

	const report = React.useCallback(
		(eventType: EventType, action?: EventAction): void => {
			const event = {
				event: {
					action,
					eventType
				},
				objectId,
				objectType
			};

			reportEvent(event);
		},
		[objectId, objectType]
	);

	const handlers = React.useMemo(
		() => ({
			onEnded: (): void => {
				report(EventType.DATA_VIEW_COMPLETED);
			},

			onError: (event: VideoEvent): void => {
				const error = event.currentTarget.error;

				if (error) {
					const action: EventAction = {
						code: error.code,
						message: error.message
					};
					report(EventType.DATA_VIEW_FAILED, action);
					return;
				}

				report(EventType.DATA_VIEW_FAILED);
			},

			onPause: (event: VideoEvent): void => {
				const time = event.currentTarget.currentTime;
				report(EventType.VIDEO_PAUSED, {
					playbackId,
					time
				});
			},

			onPlay: (event: VideoEvent): void => {
				const time = event.currentTarget.currentTime;
				report(EventType.DATA_VIEWED);
				report(EventType.VIDEO_PLAYED, {
					playbackId,
					time
				});
			},

			onSeeked: (event: VideoEvent): void => {
				const time = event.currentTarget.currentTime;
				report(EventType.VIDEO_SEEKED, {
					playbackId,
					time
				});
			},

			onTimeUpdate: throttle((event: VideoEvent): void => {
				const time = event.currentTarget.currentTime;
				report(EventType.VIDEO_HEARTBEAT, {
					playbackId,
					time
				});
			}, TEN_SECONDS)
		}),
		[report, playbackId]
	);

	if (!SUPPORTED_OBJECTS.includes(objectType)) {
		throw new Error(`Passed object type ${objectType} does not support video watch events`);
	}

	return handlers;
}

export default useVideoWatchReporting;
export { UseVideoWatchReporting, UseVideoWatchReportingOptions };

//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./api_types');
//HELPER FUNCTIONS AND STRUCTURES

var CommonService_create_video_visit_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreateVideoVisitRequest(args.request);
    }
  }
};
CommonService_create_video_visit_args.prototype = {};
CommonService_create_video_visit_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreateVideoVisitRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_create_video_visit_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_create_video_visit_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_create_video_visit_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CreateVideoVisitResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_create_video_visit_result.prototype = {};
CommonService_create_video_visit_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CreateVideoVisitResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_create_video_visit_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_create_video_visit_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_disable_2fa_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.Disable2FARequest(args.request);
    }
  }
};
CommonService_disable_2fa_args.prototype = {};
CommonService_disable_2fa_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.Disable2FARequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_disable_2fa_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_disable_2fa_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_disable_2fa_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Disable2FAResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_disable_2fa_result.prototype = {};
CommonService_disable_2fa_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Disable2FAResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_disable_2fa_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_disable_2fa_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_case_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetCaseRequest(args.request);
    }
  }
};
CommonService_get_case_args.prototype = {};
CommonService_get_case_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetCaseRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_case_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_case_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_case_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  this.resource_moved = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args instanceof ttypes.ResourceMoved) {
    this.resource_moved = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetCaseResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
    if (args.resource_moved !== undefined && args.resource_moved !== null) {
      this.resource_moved = args.resource_moved;
    }
  }
};
CommonService_get_case_result.prototype = {};
CommonService_get_case_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetCaseResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.resource_moved = new ttypes.ResourceMoved();
        this.resource_moved.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_case_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_case_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  if (this.resource_moved !== null && this.resource_moved !== undefined) {
    output.writeFieldBegin('resource_moved', Thrift.Type.STRUCT, 2);
    this.resource_moved.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_cases_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetCasesRequest(args.request);
    }
  }
};
CommonService_get_cases_args.prototype = {};
CommonService_get_cases_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetCasesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_cases_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_cases_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_cases_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetCasesResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_cases_result.prototype = {};
CommonService_get_cases_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetCasesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_cases_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_cases_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_clinical_review_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetClinicalReviewRequest(args.request);
    }
  }
};
CommonService_get_clinical_review_args.prototype = {};
CommonService_get_clinical_review_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetClinicalReviewRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_clinical_review_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_clinical_review_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_clinical_review_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetClinicalReviewResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_clinical_review_result.prototype = {};
CommonService_get_clinical_review_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetClinicalReviewResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_clinical_review_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_clinical_review_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consult_data_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetConsultDataRequest(args.request);
    }
  }
};
CommonService_get_consult_data_args.prototype = {};
CommonService_get_consult_data_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetConsultDataRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consult_data_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consult_data_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consult_data_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetConsultDataResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_consult_data_result.prototype = {};
CommonService_get_consult_data_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetConsultDataResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consult_data_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consult_data_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consult_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetConsultRequest(args.request);
    }
  }
};
CommonService_get_consult_args.prototype = {};
CommonService_get_consult_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetConsultRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consult_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consult_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consult_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetConsultResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_consult_result.prototype = {};
CommonService_get_consult_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetConsultResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consult_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consult_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consults_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetConsultsRequest(args.request);
    }
  }
};
CommonService_get_consults_args.prototype = {};
CommonService_get_consults_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetConsultsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consults_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consults_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_consults_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetConsultsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_consults_result.prototype = {};
CommonService_get_consults_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetConsultsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_consults_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_consults_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_conversations_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetConversationsRequest(args.request);
    }
  }
};
CommonService_get_conversations_args.prototype = {};
CommonService_get_conversations_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetConversationsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_conversations_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_conversations_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_conversations_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetConversationsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_conversations_result.prototype = {};
CommonService_get_conversations_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetConversationsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_conversations_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_conversations_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_editable_fields_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetEditableFieldsRequest(args.request);
    }
  }
};
CommonService_get_editable_fields_args.prototype = {};
CommonService_get_editable_fields_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetEditableFieldsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_editable_fields_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_editable_fields_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_editable_fields_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetEditableFieldsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_editable_fields_result.prototype = {};
CommonService_get_editable_fields_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetEditableFieldsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_editable_fields_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_editable_fields_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_emails_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetEmailsRequest(args.request);
    }
  }
};
CommonService_get_emails_args.prototype = {};
CommonService_get_emails_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetEmailsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_emails_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_emails_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_emails_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetEmailsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_emails_result.prototype = {};
CommonService_get_emails_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetEmailsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_emails_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_emails_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_history_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetHistoryRequest(args.request);
    }
  }
};
CommonService_get_history_args.prototype = {};
CommonService_get_history_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetHistoryRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_history_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_history_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_history_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetHistoryResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_history_result.prototype = {};
CommonService_get_history_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetHistoryResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_history_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_history_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_partners_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetPartnersRequest(args.request);
    }
  }
};
CommonService_get_partners_args.prototype = {};
CommonService_get_partners_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetPartnersRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_partners_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_partners_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_partners_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetPartnersResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_partners_result.prototype = {};
CommonService_get_partners_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetPartnersResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_partners_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_partners_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_shared_patient_link_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetSharedPatientLinkRequest(args.request);
    }
  }
};
CommonService_get_shared_patient_link_args.prototype = {};
CommonService_get_shared_patient_link_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetSharedPatientLinkRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_shared_patient_link_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_shared_patient_link_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_shared_patient_link_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetSharedPatientLinkResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_shared_patient_link_result.prototype = {};
CommonService_get_shared_patient_link_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetSharedPatientLinkResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_shared_patient_link_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_shared_patient_link_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_updates_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetUpdatesRequest(args.request);
    }
  }
};
CommonService_get_updates_args.prototype = {};
CommonService_get_updates_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetUpdatesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_updates_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_updates_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_updates_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetUpdatesResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_updates_result.prototype = {};
CommonService_get_updates_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetUpdatesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_updates_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_updates_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_upload_bucket_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetUploadBucketRequest(args.request);
    }
  }
};
CommonService_get_upload_bucket_args.prototype = {};
CommonService_get_upload_bucket_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetUploadBucketRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_upload_bucket_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_upload_bucket_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_upload_bucket_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetUploadBucketResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_upload_bucket_result.prototype = {};
CommonService_get_upload_bucket_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetUploadBucketResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_upload_bucket_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_upload_bucket_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_service_access_params_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetServiceAccessParamsRequest(args.request);
    }
  }
};
CommonService_get_service_access_params_args.prototype = {};
CommonService_get_service_access_params_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetServiceAccessParamsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_service_access_params_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_service_access_params_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_service_access_params_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetServiceAccessParamsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_service_access_params_result.prototype = {};
CommonService_get_service_access_params_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetServiceAccessParamsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_service_access_params_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_service_access_params_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_upload_params_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetUploadParamsRequest(args.request);
    }
  }
};
CommonService_get_upload_params_args.prototype = {};
CommonService_get_upload_params_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetUploadParamsRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_upload_params_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_upload_params_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_upload_params_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetUploadParamsResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_upload_params_result.prototype = {};
CommonService_get_upload_params_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetUploadParamsResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_upload_params_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_upload_params_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_user_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetUserRequest(args.request);
    }
  }
};
CommonService_get_user_args.prototype = {};
CommonService_get_user_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_user_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_user_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_user_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  this.resource_moved = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args instanceof ttypes.ResourceMoved) {
    this.resource_moved = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetUserResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
    if (args.resource_moved !== undefined && args.resource_moved !== null) {
      this.resource_moved = args.resource_moved;
    }
  }
};
CommonService_get_user_result.prototype = {};
CommonService_get_user_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetUserResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.resource_moved = new ttypes.ResourceMoved();
        this.resource_moved.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_user_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_user_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  if (this.resource_moved !== null && this.resource_moved !== undefined) {
    output.writeFieldBegin('resource_moved', Thrift.Type.STRUCT, 2);
    this.resource_moved.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_users_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetUsersRequest(args.request);
    }
  }
};
CommonService_get_users_args.prototype = {};
CommonService_get_users_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetUsersRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_users_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_users_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_get_users_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetUsersResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_get_users_result.prototype = {};
CommonService_get_users_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetUsersResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_get_users_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_get_users_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_join_video_visit_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.JoinVideoVisitRequest(args.request);
    }
  }
};
CommonService_join_video_visit_args.prototype = {};
CommonService_join_video_visit_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.JoinVideoVisitRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_join_video_visit_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_join_video_visit_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_join_video_visit_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.JoinVideoVisitResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_join_video_visit_result.prototype = {};
CommonService_join_video_visit_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.JoinVideoVisitResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_join_video_visit_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_join_video_visit_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_case_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ManageCaseRequest(args.request);
    }
  }
};
CommonService_manage_case_args.prototype = {};
CommonService_manage_case_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ManageCaseRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_case_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_case_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_case_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ManageCaseResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_manage_case_result.prototype = {};
CommonService_manage_case_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ManageCaseResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_case_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_case_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_consult_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ManageConsultRequest(args.request);
    }
  }
};
CommonService_manage_consult_args.prototype = {};
CommonService_manage_consult_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ManageConsultRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_consult_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_consult_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_consult_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ManageConsultResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_manage_consult_result.prototype = {};
CommonService_manage_consult_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ManageConsultResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_consult_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_consult_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_conversation_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ManageConversationRequest(args.request);
    }
  }
};
CommonService_manage_conversation_args.prototype = {};
CommonService_manage_conversation_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ManageConversationRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_conversation_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_conversation_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_manage_conversation_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ManageConversationResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_manage_conversation_result.prototype = {};
CommonService_manage_conversation_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ManageConversationResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_manage_conversation_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_manage_conversation_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_report_event_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ReportEventRequest(args.request);
    }
  }
};
CommonService_report_event_args.prototype = {};
CommonService_report_event_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ReportEventRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_report_event_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_report_event_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_report_event_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ReportEventResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_report_event_result.prototype = {};
CommonService_report_event_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ReportEventResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_report_event_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_report_event_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_resend_conversation_message_notification_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ResendConversationMessageNotificationRequest(args.request);
    }
  }
};
CommonService_resend_conversation_message_notification_args.prototype = {};
CommonService_resend_conversation_message_notification_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ResendConversationMessageNotificationRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_resend_conversation_message_notification_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_resend_conversation_message_notification_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_resend_conversation_message_notification_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResendConversationMessageNotificationResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_resend_conversation_message_notification_result.prototype = {};
CommonService_resend_conversation_message_notification_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResendConversationMessageNotificationResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_resend_conversation_message_notification_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_resend_conversation_message_notification_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_send_video_visit_link_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.SendVideoVisitLinkRequest(args.request);
    }
  }
};
CommonService_send_video_visit_link_args.prototype = {};
CommonService_send_video_visit_link_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.SendVideoVisitLinkRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_send_video_visit_link_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_send_video_visit_link_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_send_video_visit_link_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.SendVideoVisitLinkResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_send_video_visit_link_result.prototype = {};
CommonService_send_video_visit_link_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.SendVideoVisitLinkResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_send_video_visit_link_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_send_video_visit_link_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_clinical_review_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdateClinicalReviewRequest(args.request);
    }
  }
};
CommonService_update_clinical_review_args.prototype = {};
CommonService_update_clinical_review_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdateClinicalReviewRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_clinical_review_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_clinical_review_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_clinical_review_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UpdateClinicalReviewResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_update_clinical_review_result.prototype = {};
CommonService_update_clinical_review_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UpdateClinicalReviewResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_clinical_review_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_clinical_review_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_password_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdatePasswordRequest(args.request);
    }
  }
};
CommonService_update_password_args.prototype = {};
CommonService_update_password_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdatePasswordRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_password_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_password_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_password_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UpdatePasswordResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_update_password_result.prototype = {};
CommonService_update_password_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UpdatePasswordResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_password_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_password_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_user_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdateUserRequest(args.request);
    }
  }
};
CommonService_update_user_args.prototype = {};
CommonService_update_user_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdateUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_user_args.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_user_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonService_update_user_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UpdateUserResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
CommonService_update_user_result.prototype = {};
CommonService_update_user_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UpdateUserResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CommonService_update_user_result.prototype.write = function(output) {
  output.writeStructBegin('CommonService_update_user_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CommonServiceClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
CommonServiceClient.prototype = {};
CommonServiceClient.prototype.seqid = function() { return this._seqid; };
CommonServiceClient.prototype.new_seqid = function() { return this._seqid += 1; };

CommonServiceClient.prototype.create_video_visit = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_create_video_visit(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_create_video_visit(request);
  }
};

CommonServiceClient.prototype.send_create_video_visit = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_create_video_visit_args(params);
  try {
    output.writeMessageBegin('create_video_visit', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_create_video_visit = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_create_video_visit_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('create_video_visit failed: unknown result');
};

CommonServiceClient.prototype.disable_2fa = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_disable_2fa(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_disable_2fa(request);
  }
};

CommonServiceClient.prototype.send_disable_2fa = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_disable_2fa_args(params);
  try {
    output.writeMessageBegin('disable_2fa', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_disable_2fa = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_disable_2fa_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('disable_2fa failed: unknown result');
};

CommonServiceClient.prototype.get_case = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_case(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_case(request);
  }
};

CommonServiceClient.prototype.send_get_case = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_case_args(params);
  try {
    output.writeMessageBegin('get_case', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_case = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_case_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.resource_moved) {
    return callback(result.resource_moved);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_case failed: unknown result');
};

CommonServiceClient.prototype.get_cases = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_cases(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_cases(request);
  }
};

CommonServiceClient.prototype.send_get_cases = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_cases_args(params);
  try {
    output.writeMessageBegin('get_cases', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_cases = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_cases_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_cases failed: unknown result');
};

CommonServiceClient.prototype.get_clinical_review = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_clinical_review(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_clinical_review(request);
  }
};

CommonServiceClient.prototype.send_get_clinical_review = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_clinical_review_args(params);
  try {
    output.writeMessageBegin('get_clinical_review', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_clinical_review = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_clinical_review_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_clinical_review failed: unknown result');
};

CommonServiceClient.prototype.get_consult_data = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_consult_data(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_consult_data(request);
  }
};

CommonServiceClient.prototype.send_get_consult_data = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_consult_data_args(params);
  try {
    output.writeMessageBegin('get_consult_data', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_consult_data = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_consult_data_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_consult_data failed: unknown result');
};

CommonServiceClient.prototype.get_consult = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_consult(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_consult(request);
  }
};

CommonServiceClient.prototype.send_get_consult = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_consult_args(params);
  try {
    output.writeMessageBegin('get_consult', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_consult = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_consult_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_consult failed: unknown result');
};

CommonServiceClient.prototype.get_consults = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_consults(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_consults(request);
  }
};

CommonServiceClient.prototype.send_get_consults = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_consults_args(params);
  try {
    output.writeMessageBegin('get_consults', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_consults = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_consults_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_consults failed: unknown result');
};

CommonServiceClient.prototype.get_conversations = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_conversations(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_conversations(request);
  }
};

CommonServiceClient.prototype.send_get_conversations = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_conversations_args(params);
  try {
    output.writeMessageBegin('get_conversations', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_conversations = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_conversations_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_conversations failed: unknown result');
};

CommonServiceClient.prototype.get_editable_fields = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_editable_fields(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_editable_fields(request);
  }
};

CommonServiceClient.prototype.send_get_editable_fields = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_editable_fields_args(params);
  try {
    output.writeMessageBegin('get_editable_fields', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_editable_fields = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_editable_fields_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_editable_fields failed: unknown result');
};

CommonServiceClient.prototype.get_emails = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_emails(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_emails(request);
  }
};

CommonServiceClient.prototype.send_get_emails = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_emails_args(params);
  try {
    output.writeMessageBegin('get_emails', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_emails = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_emails_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_emails failed: unknown result');
};

CommonServiceClient.prototype.get_history = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_history(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_history(request);
  }
};

CommonServiceClient.prototype.send_get_history = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_history_args(params);
  try {
    output.writeMessageBegin('get_history', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_history = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_history_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_history failed: unknown result');
};

CommonServiceClient.prototype.get_partners = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_partners(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_partners(request);
  }
};

CommonServiceClient.prototype.send_get_partners = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_partners_args(params);
  try {
    output.writeMessageBegin('get_partners', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_partners = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_partners_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_partners failed: unknown result');
};

CommonServiceClient.prototype.get_shared_patient_link = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_shared_patient_link(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_shared_patient_link(request);
  }
};

CommonServiceClient.prototype.send_get_shared_patient_link = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_shared_patient_link_args(params);
  try {
    output.writeMessageBegin('get_shared_patient_link', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_shared_patient_link = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_shared_patient_link_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_shared_patient_link failed: unknown result');
};

CommonServiceClient.prototype.get_updates = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_updates(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_updates(request);
  }
};

CommonServiceClient.prototype.send_get_updates = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_updates_args(params);
  try {
    output.writeMessageBegin('get_updates', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_updates = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_updates_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_updates failed: unknown result');
};

CommonServiceClient.prototype.get_upload_bucket = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_upload_bucket(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_upload_bucket(request);
  }
};

CommonServiceClient.prototype.send_get_upload_bucket = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_upload_bucket_args(params);
  try {
    output.writeMessageBegin('get_upload_bucket', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_upload_bucket = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_upload_bucket_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_upload_bucket failed: unknown result');
};

CommonServiceClient.prototype.get_service_access_params = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_service_access_params(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_service_access_params(request);
  }
};

CommonServiceClient.prototype.send_get_service_access_params = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_service_access_params_args(params);
  try {
    output.writeMessageBegin('get_service_access_params', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_service_access_params = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_service_access_params_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_service_access_params failed: unknown result');
};

CommonServiceClient.prototype.get_upload_params = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_upload_params(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_upload_params(request);
  }
};

CommonServiceClient.prototype.send_get_upload_params = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_upload_params_args(params);
  try {
    output.writeMessageBegin('get_upload_params', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_upload_params = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_upload_params_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_upload_params failed: unknown result');
};

CommonServiceClient.prototype.get_user = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_user(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_user(request);
  }
};

CommonServiceClient.prototype.send_get_user = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_user_args(params);
  try {
    output.writeMessageBegin('get_user', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_user = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_user_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.resource_moved) {
    return callback(result.resource_moved);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_user failed: unknown result');
};

CommonServiceClient.prototype.get_users = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_users(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_users(request);
  }
};

CommonServiceClient.prototype.send_get_users = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_get_users_args(params);
  try {
    output.writeMessageBegin('get_users', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_get_users = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_get_users_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_users failed: unknown result');
};

CommonServiceClient.prototype.join_video_visit = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_join_video_visit(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_join_video_visit(request);
  }
};

CommonServiceClient.prototype.send_join_video_visit = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_join_video_visit_args(params);
  try {
    output.writeMessageBegin('join_video_visit', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_join_video_visit = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_join_video_visit_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('join_video_visit failed: unknown result');
};

CommonServiceClient.prototype.manage_case = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_manage_case(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_manage_case(request);
  }
};

CommonServiceClient.prototype.send_manage_case = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_manage_case_args(params);
  try {
    output.writeMessageBegin('manage_case', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_manage_case = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_manage_case_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('manage_case failed: unknown result');
};

CommonServiceClient.prototype.manage_consult = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_manage_consult(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_manage_consult(request);
  }
};

CommonServiceClient.prototype.send_manage_consult = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_manage_consult_args(params);
  try {
    output.writeMessageBegin('manage_consult', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_manage_consult = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_manage_consult_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('manage_consult failed: unknown result');
};

CommonServiceClient.prototype.manage_conversation = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_manage_conversation(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_manage_conversation(request);
  }
};

CommonServiceClient.prototype.send_manage_conversation = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_manage_conversation_args(params);
  try {
    output.writeMessageBegin('manage_conversation', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_manage_conversation = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_manage_conversation_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('manage_conversation failed: unknown result');
};

CommonServiceClient.prototype.report_event = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_report_event(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_report_event(request);
  }
};

CommonServiceClient.prototype.send_report_event = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_report_event_args(params);
  try {
    output.writeMessageBegin('report_event', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_report_event = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_report_event_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('report_event failed: unknown result');
};

CommonServiceClient.prototype.resend_conversation_message_notification = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_resend_conversation_message_notification(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_resend_conversation_message_notification(request);
  }
};

CommonServiceClient.prototype.send_resend_conversation_message_notification = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_resend_conversation_message_notification_args(params);
  try {
    output.writeMessageBegin('resend_conversation_message_notification', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_resend_conversation_message_notification = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_resend_conversation_message_notification_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('resend_conversation_message_notification failed: unknown result');
};

CommonServiceClient.prototype.send_video_visit_link = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_send_video_visit_link(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_send_video_visit_link(request);
  }
};

CommonServiceClient.prototype.send_send_video_visit_link = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_send_video_visit_link_args(params);
  try {
    output.writeMessageBegin('send_video_visit_link', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_send_video_visit_link = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_send_video_visit_link_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('send_video_visit_link failed: unknown result');
};

CommonServiceClient.prototype.update_clinical_review = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_update_clinical_review(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_update_clinical_review(request);
  }
};

CommonServiceClient.prototype.send_update_clinical_review = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_update_clinical_review_args(params);
  try {
    output.writeMessageBegin('update_clinical_review', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_update_clinical_review = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_update_clinical_review_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('update_clinical_review failed: unknown result');
};

CommonServiceClient.prototype.update_password = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_update_password(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_update_password(request);
  }
};

CommonServiceClient.prototype.send_update_password = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_update_password_args(params);
  try {
    output.writeMessageBegin('update_password', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_update_password = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_update_password_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('update_password failed: unknown result');
};

CommonServiceClient.prototype.update_user = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_update_user(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_update_user(request);
  }
};

CommonServiceClient.prototype.send_update_user = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new CommonService_update_user_args(params);
  try {
    output.writeMessageBegin('update_user', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

CommonServiceClient.prototype.recv_update_user = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new CommonService_update_user_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('update_user failed: unknown result');
};
var CommonServiceProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
CommonServiceProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
CommonServiceProcessor.prototype.process_create_video_visit = function(seqid, input, output) {
  var args = new CommonService_create_video_visit_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.create_video_visit.length === 1) {
    Q.fcall(this._handler.create_video_visit.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_create_video_visit_result({success: result});
      output.writeMessageBegin("create_video_visit", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_create_video_visit_result(err);
        output.writeMessageBegin("create_video_visit", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_video_visit", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.create_video_visit(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_create_video_visit_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("create_video_visit", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_video_visit", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_disable_2fa = function(seqid, input, output) {
  var args = new CommonService_disable_2fa_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.disable_2fa.length === 1) {
    Q.fcall(this._handler.disable_2fa.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_disable_2fa_result({success: result});
      output.writeMessageBegin("disable_2fa", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_disable_2fa_result(err);
        output.writeMessageBegin("disable_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("disable_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.disable_2fa(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_disable_2fa_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("disable_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("disable_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_case = function(seqid, input, output) {
  var args = new CommonService_get_case_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_case.length === 1) {
    Q.fcall(this._handler.get_case.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_case_result({success: result});
      output.writeMessageBegin("get_case", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest || err instanceof ttypes.ResourceMoved) {
        result = new CommonService_get_case_result(err);
        output.writeMessageBegin("get_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_case(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest || err instanceof ttypes.ResourceMoved) {
        result_obj = new CommonService_get_case_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_cases = function(seqid, input, output) {
  var args = new CommonService_get_cases_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_cases.length === 1) {
    Q.fcall(this._handler.get_cases.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_cases_result({success: result});
      output.writeMessageBegin("get_cases", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_cases_result(err);
        output.writeMessageBegin("get_cases", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_cases", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_cases(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_cases_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_cases", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_cases", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_clinical_review = function(seqid, input, output) {
  var args = new CommonService_get_clinical_review_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_clinical_review.length === 1) {
    Q.fcall(this._handler.get_clinical_review.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_clinical_review_result({success: result});
      output.writeMessageBegin("get_clinical_review", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_clinical_review_result(err);
        output.writeMessageBegin("get_clinical_review", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_clinical_review", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_clinical_review(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_clinical_review_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_clinical_review", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_clinical_review", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_consult_data = function(seqid, input, output) {
  var args = new CommonService_get_consult_data_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_consult_data.length === 1) {
    Q.fcall(this._handler.get_consult_data.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_consult_data_result({success: result});
      output.writeMessageBegin("get_consult_data", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_consult_data_result(err);
        output.writeMessageBegin("get_consult_data", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult_data", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_consult_data(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_consult_data_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_consult_data", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult_data", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_consult = function(seqid, input, output) {
  var args = new CommonService_get_consult_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_consult.length === 1) {
    Q.fcall(this._handler.get_consult.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_consult_result({success: result});
      output.writeMessageBegin("get_consult", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_consult_result(err);
        output.writeMessageBegin("get_consult", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_consult(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_consult_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_consult", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_consults = function(seqid, input, output) {
  var args = new CommonService_get_consults_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_consults.length === 1) {
    Q.fcall(this._handler.get_consults.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_consults_result({success: result});
      output.writeMessageBegin("get_consults", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_consults_result(err);
        output.writeMessageBegin("get_consults", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consults", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_consults(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_consults_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_consults", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consults", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_conversations = function(seqid, input, output) {
  var args = new CommonService_get_conversations_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_conversations.length === 1) {
    Q.fcall(this._handler.get_conversations.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_conversations_result({success: result});
      output.writeMessageBegin("get_conversations", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_conversations_result(err);
        output.writeMessageBegin("get_conversations", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_conversations", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_conversations(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_conversations_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_conversations", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_conversations", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_editable_fields = function(seqid, input, output) {
  var args = new CommonService_get_editable_fields_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_editable_fields.length === 1) {
    Q.fcall(this._handler.get_editable_fields.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_editable_fields_result({success: result});
      output.writeMessageBegin("get_editable_fields", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_editable_fields_result(err);
        output.writeMessageBegin("get_editable_fields", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_editable_fields", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_editable_fields(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_editable_fields_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_editable_fields", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_editable_fields", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_emails = function(seqid, input, output) {
  var args = new CommonService_get_emails_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_emails.length === 1) {
    Q.fcall(this._handler.get_emails.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_emails_result({success: result});
      output.writeMessageBegin("get_emails", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_emails_result(err);
        output.writeMessageBegin("get_emails", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_emails", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_emails(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_emails_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_emails", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_emails", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_history = function(seqid, input, output) {
  var args = new CommonService_get_history_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_history.length === 1) {
    Q.fcall(this._handler.get_history.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_history_result({success: result});
      output.writeMessageBegin("get_history", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_history_result(err);
        output.writeMessageBegin("get_history", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_history", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_history(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_history_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_history", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_history", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_partners = function(seqid, input, output) {
  var args = new CommonService_get_partners_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_partners.length === 1) {
    Q.fcall(this._handler.get_partners.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_partners_result({success: result});
      output.writeMessageBegin("get_partners", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_partners_result(err);
        output.writeMessageBegin("get_partners", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_partners", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_partners(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_partners_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_partners", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_partners", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_shared_patient_link = function(seqid, input, output) {
  var args = new CommonService_get_shared_patient_link_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_shared_patient_link.length === 1) {
    Q.fcall(this._handler.get_shared_patient_link.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_shared_patient_link_result({success: result});
      output.writeMessageBegin("get_shared_patient_link", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_shared_patient_link_result(err);
        output.writeMessageBegin("get_shared_patient_link", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_shared_patient_link", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_shared_patient_link(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_shared_patient_link_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_shared_patient_link", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_shared_patient_link", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_updates = function(seqid, input, output) {
  var args = new CommonService_get_updates_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_updates.length === 1) {
    Q.fcall(this._handler.get_updates.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_updates_result({success: result});
      output.writeMessageBegin("get_updates", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_updates_result(err);
        output.writeMessageBegin("get_updates", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_updates", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_updates(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_updates_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_updates", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_updates", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_upload_bucket = function(seqid, input, output) {
  var args = new CommonService_get_upload_bucket_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_upload_bucket.length === 1) {
    Q.fcall(this._handler.get_upload_bucket.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_upload_bucket_result({success: result});
      output.writeMessageBegin("get_upload_bucket", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_upload_bucket_result(err);
        output.writeMessageBegin("get_upload_bucket", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_upload_bucket", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_upload_bucket(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_upload_bucket_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_upload_bucket", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_upload_bucket", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_service_access_params = function(seqid, input, output) {
  var args = new CommonService_get_service_access_params_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_service_access_params.length === 1) {
    Q.fcall(this._handler.get_service_access_params.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_service_access_params_result({success: result});
      output.writeMessageBegin("get_service_access_params", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_service_access_params_result(err);
        output.writeMessageBegin("get_service_access_params", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_service_access_params", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_service_access_params(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_service_access_params_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_service_access_params", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_service_access_params", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_upload_params = function(seqid, input, output) {
  var args = new CommonService_get_upload_params_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_upload_params.length === 1) {
    Q.fcall(this._handler.get_upload_params.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_upload_params_result({success: result});
      output.writeMessageBegin("get_upload_params", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_upload_params_result(err);
        output.writeMessageBegin("get_upload_params", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_upload_params", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_upload_params(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_upload_params_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_upload_params", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_upload_params", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_user = function(seqid, input, output) {
  var args = new CommonService_get_user_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_user.length === 1) {
    Q.fcall(this._handler.get_user.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_user_result({success: result});
      output.writeMessageBegin("get_user", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest || err instanceof ttypes.ResourceMoved) {
        result = new CommonService_get_user_result(err);
        output.writeMessageBegin("get_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_user(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest || err instanceof ttypes.ResourceMoved) {
        result_obj = new CommonService_get_user_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_get_users = function(seqid, input, output) {
  var args = new CommonService_get_users_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_users.length === 1) {
    Q.fcall(this._handler.get_users.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_get_users_result({success: result});
      output.writeMessageBegin("get_users", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_get_users_result(err);
        output.writeMessageBegin("get_users", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_users", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_users(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_get_users_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_users", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_users", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_join_video_visit = function(seqid, input, output) {
  var args = new CommonService_join_video_visit_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.join_video_visit.length === 1) {
    Q.fcall(this._handler.join_video_visit.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_join_video_visit_result({success: result});
      output.writeMessageBegin("join_video_visit", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_join_video_visit_result(err);
        output.writeMessageBegin("join_video_visit", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("join_video_visit", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.join_video_visit(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_join_video_visit_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("join_video_visit", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("join_video_visit", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_manage_case = function(seqid, input, output) {
  var args = new CommonService_manage_case_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.manage_case.length === 1) {
    Q.fcall(this._handler.manage_case.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_manage_case_result({success: result});
      output.writeMessageBegin("manage_case", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_manage_case_result(err);
        output.writeMessageBegin("manage_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.manage_case(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_manage_case_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("manage_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_manage_consult = function(seqid, input, output) {
  var args = new CommonService_manage_consult_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.manage_consult.length === 1) {
    Q.fcall(this._handler.manage_consult.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_manage_consult_result({success: result});
      output.writeMessageBegin("manage_consult", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_manage_consult_result(err);
        output.writeMessageBegin("manage_consult", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_consult", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.manage_consult(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_manage_consult_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("manage_consult", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_consult", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_manage_conversation = function(seqid, input, output) {
  var args = new CommonService_manage_conversation_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.manage_conversation.length === 1) {
    Q.fcall(this._handler.manage_conversation.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_manage_conversation_result({success: result});
      output.writeMessageBegin("manage_conversation", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_manage_conversation_result(err);
        output.writeMessageBegin("manage_conversation", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_conversation", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.manage_conversation(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_manage_conversation_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("manage_conversation", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("manage_conversation", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_report_event = function(seqid, input, output) {
  var args = new CommonService_report_event_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.report_event.length === 1) {
    Q.fcall(this._handler.report_event.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_report_event_result({success: result});
      output.writeMessageBegin("report_event", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_report_event_result(err);
        output.writeMessageBegin("report_event", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("report_event", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.report_event(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_report_event_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("report_event", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("report_event", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_resend_conversation_message_notification = function(seqid, input, output) {
  var args = new CommonService_resend_conversation_message_notification_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.resend_conversation_message_notification.length === 1) {
    Q.fcall(this._handler.resend_conversation_message_notification.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_resend_conversation_message_notification_result({success: result});
      output.writeMessageBegin("resend_conversation_message_notification", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_resend_conversation_message_notification_result(err);
        output.writeMessageBegin("resend_conversation_message_notification", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resend_conversation_message_notification", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.resend_conversation_message_notification(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_resend_conversation_message_notification_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("resend_conversation_message_notification", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resend_conversation_message_notification", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_send_video_visit_link = function(seqid, input, output) {
  var args = new CommonService_send_video_visit_link_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.send_video_visit_link.length === 1) {
    Q.fcall(this._handler.send_video_visit_link.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_send_video_visit_link_result({success: result});
      output.writeMessageBegin("send_video_visit_link", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_send_video_visit_link_result(err);
        output.writeMessageBegin("send_video_visit_link", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("send_video_visit_link", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.send_video_visit_link(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_send_video_visit_link_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("send_video_visit_link", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("send_video_visit_link", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_update_clinical_review = function(seqid, input, output) {
  var args = new CommonService_update_clinical_review_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.update_clinical_review.length === 1) {
    Q.fcall(this._handler.update_clinical_review.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_update_clinical_review_result({success: result});
      output.writeMessageBegin("update_clinical_review", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_update_clinical_review_result(err);
        output.writeMessageBegin("update_clinical_review", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_clinical_review", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.update_clinical_review(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_update_clinical_review_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("update_clinical_review", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_clinical_review", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_update_password = function(seqid, input, output) {
  var args = new CommonService_update_password_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.update_password.length === 1) {
    Q.fcall(this._handler.update_password.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_update_password_result({success: result});
      output.writeMessageBegin("update_password", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_update_password_result(err);
        output.writeMessageBegin("update_password", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_password", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.update_password(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_update_password_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("update_password", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_password", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
CommonServiceProcessor.prototype.process_update_user = function(seqid, input, output) {
  var args = new CommonService_update_user_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.update_user.length === 1) {
    Q.fcall(this._handler.update_user.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new CommonService_update_user_result({success: result});
      output.writeMessageBegin("update_user", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new CommonService_update_user_result(err);
        output.writeMessageBegin("update_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.update_user(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new CommonService_update_user_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("update_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};

import { USER_FILTERS } from 'api/customFilters';
import { transformToThriftFilter } from 'api/helpers';
import { PartnerAssociation } from 'api/types';
import { USER_SEARCH_FILTER } from 'components/Filters';
import { UsersLoadError } from 'components/Messages';
import { Pagination } from 'components/Pagination';
import { usePaginationContext } from 'contexts/PaginationContext';
import { Loader } from 'display';
import { Parameters } from 'hooks/pagination';
import { UsersQuery } from 'hooks/queries/user/types';
import useUsersQuery from 'hooks/queries/user/useUsersQuery';
import { objectCamelToSnake } from 'lib/objectConverter';
import Strings from 'lib/strings';
import * as React from 'react';
import { Partner, User } from 'thriftgen/api_types';
import { GetUsersRequestCamel } from 'thriftgen/thriftCamelTypes';
import { DataTableColumn } from '../DataTable';
import UserFilters from './filters';
import UserTable from './UserTable';

interface PartnerUsersTableProps {
	canEdit: boolean;
	partnerId: Partner['partner_id'];
	actions: Array<JSX.Element>;
}

type PartnerAssociationMutators = Pick<
	UsersQuery,
	'addAdminToPartner' | 'removeAdminFromPartner' | 'setPartnerMemberRoles'
>;

const UI_FORM_FILTERS = [USER_SEARCH_FILTER];
const UI_POPOVER_FILTERS = [UserFilters.buildActive()];

function PartnerUsersTable(props: PartnerUsersTableProps): JSX.Element {
	const defaultFilters = [
		{
			attribute: 'is_active',
			value: ['true']
		}
	];

	return (
		<Pagination initialFilters={defaultFilters}>
			<Pagination.FilterFormWithActions filters={UI_FORM_FILTERS} actions={props.actions} />
			<Pagination.FilterPopover filters={UI_POPOVER_FILTERS} />
			<PaginatedPartnerUsersTable {...props} />
			<Pagination.AllPageControls />
		</Pagination>
	);
}

function buildColumns(
	partnerId: Partner['partner_id'],
	mutators: PartnerAssociationMutators,
	canEdit: boolean
): Array<DataTableColumn<User>> {
	const columns = [
		UserTable.Columns.buildName(),
		UserTable.Columns.buildEmail(),
		UserTable.Columns.buildIsActive(),
		UserTable.Columns.buildToggleAsAdmin({ partnerId, canEdit, ...mutators }),
		UserTable.Columns.buildToggleMemberRoleCheckbox({
			partnerId,
			canEdit,
			headerLabel: Strings.Labels.BILLABLE,
			roleKey: 'is_billable',
			...mutators
		}),
		UserTable.Columns.buildToggleMemberRoleCheckbox({
			partnerId,
			canEdit,
			headerLabel: Strings.Labels.SUPPORT,
			roleKey: 'is_support',
			...mutators
		}),
		UserTable.Columns.buildToggleMemberRoleCheckbox({
			partnerId,
			canEdit,
			headerLabel: Strings.Labels.REPORTABLE,
			roleKey: 'is_reportable',
			...mutators
		}),
		UserTable.Columns.buildLinkToUserDetail()
	];

	return columns;
}

function buildRequest(
	parameters: Parameters,
	partnerId: Partner['partner_id']
): GetUsersRequestCamel {
	const { filters, pageCursor } = parameters;
	const partnerMemberFilter = {
		attribute: USER_FILTERS.MEMBER_AT_PARTNER,
		value: partnerId
	};
	const requestFilters = [...filters, partnerMemberFilter].map(transformToThriftFilter);

	return {
		pageParams: {
			...parameters,
			filters: requestFilters,
			pageCursor: pageCursor ?? undefined
		}
	};
}

function PaginatedPartnerUsersTable({ canEdit, partnerId }: PartnerUsersTableProps): JSX.Element {
	const { parameters, setNeighborPageCursors } = usePaginationContext();
	const request = React.useMemo(
		(): GetUsersRequestCamel => buildRequest(parameters, partnerId),
		[parameters, partnerId]
	);
	const {
		users,
		error,
		pageMarkers = {
			nextPageCursor: null,
			prevPageCursor: null
		},
		...mutators
	} = useUsersQuery(request);
	const columns = React.useMemo(
		(): Array<DataTableColumn<User>> => buildColumns(partnerId, mutators, canEdit),
		[partnerId, mutators, canEdit]
	);
	const { nextPageCursor, prevPageCursor } = pageMarkers;

	React.useEffect((): void => {
		setNeighborPageCursors({
			nextPageCursor,
			prevPageCursor
		});
	}, [nextPageCursor, prevPageCursor]);

	if (error) {
		return <UsersLoadError message={error.message} />;
	}

	if (users) {
		return <UserTable users={objectCamelToSnake(users)} columns={columns} />;
	}

	return <Loader active={true} data-testid="partnerUsersTable-loader" />;
}

export default PartnerUsersTable;
export { PartnerAssociation };

import React from 'react';

function getOnlineStatus() {
	return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;
}

function useOnlineStatus() {
	const [onlineStatus, setOnlineStatus] = React.useState(getOnlineStatus());

	function goOnline() {
		setOnlineStatus(true);
	}
	function goOffline() {
		setOnlineStatus(false);
	}
	React.useEffect(() => {
		window.addEventListener('online', goOnline);
		window.addEventListener('offline', goOffline);
		return () => {
			window.removeEventListener('online', goOnline);
			window.removeEventListener('offline', goOffline);
		};
	}, []);

	return onlineStatus;
}

export default useOnlineStatus;

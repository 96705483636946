import LinkButton from 'components/Buttons/LinkButtons/LinkButton';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { Button, ButtonType } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getDoctorName } from 'lib/userHelpers';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { colors, fontSize } from 'styles';

const styles = {
	mobile: css`
		text-transform: uppercase;
		color: ${colors.primary.base};
		font-size: ${fontSize[0]};
		margin-bottom: 1em;
	`
};

const header = 'Specialist Name';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ consultId, specialist }: ConsultComposite) => (
		<div>
			<LinkButton
				buttonType={ButtonType.TEXT_PRIMARY}
				text={getDoctorName(specialist, true)}
				size={Button.Size.TINY}
				link={RouteUrls.buildUrl(RouteUrls.Key.CONSULT_CLINICAL_REVIEW, {
					consultId: consultId
				})}
			/>
		</div>
	),

	renderMobile: ({ specialist }: ConsultComposite) => (
		<div className={styles.mobile}>{getDoctorName(specialist, true)}</div>
	)
});
